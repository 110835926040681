import { AppDispatch } from '../../site/types/actionTypes';
import { action } from '../../utils/functions/reduxUtils';
import axios from 'axios';
import { AppState } from '../../site/types/appState';
import { notify } from '../../site/actions/siteActions';
import { Stream } from '../types/multistream';
import { MultiVideoStreamsPayload } from '../types/multiVideoStreams';
import { getMultiVideoStreamsQueue, getStream, getAllStreams } from '../selectors/playerSelectors';
import { MaskRectangle, StreamState, FrameSize } from '../types/streamState';
import { detectedMultistreamChanges } from './playerActions';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getRequestAuthConfig } from '../../utils/functions/axiosUtils';
import { updateComponentLayers } from './streamsContainerActions';
import { StreamPayload } from '../types/payloadTypes';

export const types = {
    UPDATE_STREAM_STATE: 'stream/UPDATE_STREAM_STATE',
    HIDE_STREAM: 'stream/HIDE_STREAM',
    ADD_NEW_MULTIVIDEO_STREAMS: 'stream/ADD_NEW_MULTIVIDEO_STREAMS',
    ASSIGN_NEXT_MULTIVIDEO_STREAM: 'stream/ASSIGN_NEXT_MULTIVIDEO_STREAM',
    TOGGLE_STREAM_IN_MULTICOMPONENT: 'stream/TOGGLE_STREAM_IN_MULTICOMPONENT',
    SET_STREAM_SYNCHRONIZATION: 'stream/SET_STREAM_SYNCHRONIZATION',
    TOGGLE_STREAM_SYNCHRONIZATION: 'stream/TOGGLE_STREAM_SYNCHRONIZATION',
    ADD_PLAYER_EVENT: 'stream/ADD_PLAYER_EVENT'
};

export const setStreamDefaultMaskRectangle = (streamId: number, defaultMaskRectangle: MaskRectangle) => 
    action<StreamPayload<StreamState>>(types.UPDATE_STREAM_STATE, { streamId, data: { defaultMaskRectangle } });

export const setStreamMinimumFrameSize = (streamId: number, minimumFrameSize: FrameSize) => 
    action<StreamPayload<StreamState>>(types.UPDATE_STREAM_STATE, { streamId, data: { minimumFrameSize } });

export const setStreamConstantFrameSize = (streamId: number, constantFrameSize: FrameSize) => 
    action<StreamPayload<StreamState>>(types.UPDATE_STREAM_STATE, { streamId, data: { constantFrameSize } });

export const setStreamVolume = (streamId: number, volume: number) => 
    action<StreamPayload<StreamState>>(types.UPDATE_STREAM_STATE, { streamId, data: { volume } });

export const setStreamIsUnmuted = (streamId: number, isUnmuted: boolean) => 
    action<StreamPayload<StreamState>>(types.UPDATE_STREAM_STATE, { streamId, data: { isUnmuted } });


export const hideStream = (streamId: number, hide: boolean = true) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<StreamPayload<boolean>>(types.HIDE_STREAM, { streamId, data: hide }));
        dispatch(updateComponentLayers());
        dispatch(detectedMultistreamChanges());
    };
};

export const toggleStreamInMultiComponent = (streamId: number) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<number>(types.TOGGLE_STREAM_IN_MULTICOMPONENT, streamId));
        dispatch(detectedMultistreamChanges());
    };
}

export const requestNextMultiVideoStreamsIfNeeded = (authContext: Auth0ContextInterface, type: string, key: string) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const multiVideoStreamsQueue = getMultiVideoStreamsQueue(getState(), type, key);
            if(multiVideoStreamsQueue?.isRequesting || multiVideoStreamsQueue?.isExhausted ||
                (multiVideoStreamsQueue?.streams != null && multiVideoStreamsQueue.streams.length > 10)) {
                    return;
            } 

            const forwardCursor = multiVideoStreamsQueue?.cursor;
            dispatch(action<MultiVideoStreamsPayload>(types.ADD_NEW_MULTIVIDEO_STREAMS, { type, key, streams: [], cursor: forwardCursor, isRequesting: true }));

            const requestConfig = await getRequestAuthConfig(authContext);
            const response = await axios.get('/api/streams/next', { params: { type, key, forwardCursor }, ...requestConfig });
            var streams = (response.data.data as Array<Stream>);
            var cursor = response.data.cursor;
            dispatch(action<MultiVideoStreamsPayload>(types.ADD_NEW_MULTIVIDEO_STREAMS, { type, key, streams, cursor, isRequesting: false }));
            dispatch(assignMultiVideoStreamsIfNeeded(authContext));
        }
        catch (error) {
            dispatch(notify('Request failed: cannot request streams', 'error'));
            console.error(error);
        }
    };
};

export const assignMultiVideoStreamsIfNeeded = (authContext: Auth0ContextInterface) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        getAllStreams(getState())?.forEach(x => {
            if(x.streamType === 'MultiVideo' && x.state.multiVideoStream == null) {
                dispatch(assignNextMultiVideoStream(authContext, x.id));
            }
        });
    };
};

export const assignNextMultiVideoStream = (authContext: Auth0ContextInterface, streamId: number) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const stream = getStream(getState(), streamId);

        if(stream?.streamType === 'MultiVideo') {
            dispatch(action<number>(types.ASSIGN_NEXT_MULTIVIDEO_STREAM, streamId));
            dispatch(requestNextMultiVideoStreamsIfNeeded(authContext, stream.sourceType, stream.sourceKey));
        }
    };
};

export const unmuteOnlyThisStream = (streamId: number) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const allStreams = getAllStreams(getState());

        if (allStreams) {
            allStreams.forEach(stream => {
                const shouldBeUnmuted = stream.id === streamId;
                
                if (stream.state.isUnmuted !== shouldBeUnmuted) {
                    dispatch(setStreamIsUnmuted(stream.id, shouldBeUnmuted));
                }
            });
        }
    };
};