import React, { FunctionComponent } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fade, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import InputBase from '@material-ui/core/InputBase';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchCards, clearCards } from '../../actions/cardsActions';
import IconButton from '@material-ui/core/IconButton';
import { Fade } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    search: {
        position: 'relative',
        display: 'inline-flex',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto'
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        padding: theme.spacing(1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        paddingRight: theme.spacing(4),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch'
            }
        },
        [theme.breakpoints.up('md')]: {
            width: '8ch',
            '&:focus': {
                width: '14ch'
            }
        },
        [theme.breakpoints.up('lg')]: {
            width: '20ch',
            '&:focus': {
                width: '30ch'
            }
        }
    },
    clearButtonRoot: {
        margin: 'auto'
    }
});

const mapDispatchToProps = {
    fetchCards,
    clearCards
};

const connector = connect(null, mapDispatchToProps);

type SearchBarProps = ConnectedProps<typeof connector> & WithStyles<typeof styles> & {};

const SearchBar: FunctionComponent<SearchBarProps> = (props) => {
    const { classes } = props;
    const { fetchCards, clearCards } = props;
    const [value, setValue] = React.useState('');
    const [previousRoute, setPreviousRoute] = React.useState('/');
    const history = useHistory();
    const authContext = useAuth0();

    const requestSearch = (input: string) => {
        const trimmedInput = input.trim();

        if (trimmedInput !== '') {
            if (history.location.pathname !== '/search') {
                setPreviousRoute(history.location.pathname);
                history.push('/search');
            }
            const urlSearchQuery = encodeURIComponent(trimmedInput);
            clearCards('searchedGames');
            clearCards('searchedMultistreams');
            fetchCards(authContext, 'searchedGames', '/api/games/search?query=' + urlSearchQuery);
            fetchCards(authContext, 'searchedMultistreams', '/api/multistreams/search?query=' + urlSearchQuery);
        }
    };

    const clearSearch = () => {
        setValue('');

        if (history.location.pathname === '/search') {
            history.push(previousRoute);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;

        if (input !== '') {
            setValue(input);
            requestSearch(input);
        }
        else {
            clearSearch();
        }
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            requestSearch(value);
        } else if (event.key === 'Escape') {
            clearSearch();
        }
    };

    const handleBlur = () => {
        setValue((value) => value.trim());
    };

    return (
        <Box mr={2} className={classes.search}>
            <Box className={classes.searchIcon}>
                <SearchIcon />
            </Box>
            <InputBase
                placeholder='Search'
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={value}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
                onBlur={handleBlur}
            />

            {value !== '' &&
                <Fade in>
                    <Box position='absolute' right='4px' height='100%'>
                        <Box height='100%' display='flex'>
                            <IconButton
                                classes={{
                                    root: classes.clearButtonRoot,
                                }}
                                aria-label="clear"
                                size="small"
                                onClick={clearSearch}
                            >
                                <ClearIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                    </Box>

                </Fade>
            }

        </Box>
    );
};

export default connector(withStyles(styles)(SearchBar));