import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import CardsDeck from '../components/cards/CardsDeck';
import UserMultistreamCard from '../components/cards/UserMultistreamCard';


const UserMultistreams: FunctionComponent = (props) => {
    return (
        <Box>
            <CardsDeck id='userMultistreams' url='/api/multistreams/user' render={card => <UserMultistreamCard keyId={card.key} {...card} />} />
        </Box>
    );
}

export default UserMultistreams;