import { action } from '../../utils/functions/reduxUtils';
import axios from 'axios';
import { AppDispatch } from '../../site/types/actionTypes';
import { notify } from '../../site/actions/siteActions';
import { Stream } from '../types/multistream';
import { StreamDetailsModalData } from '../types/streamDetailsModalData';
import { getPlayer, getMultistream, getStream } from '../selectors/playerSelectors';
import { AppState } from '../../site/types/appState';
import { changeMultistreamLayout } from './streamsContainerActions';
import { NormalizedLayoutTemplates } from '../types/layoutTemplate';
import { normalizeLayoutTemplates, processStream } from './helpers/multistreamProcessHelper';
import { PlayerFlags } from '../types/playerFlags';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getRequestAuthConfig } from '../../utils/functions/axiosUtils';

export const types = {
    UPDATE_STREAM_DETAILS_MODAL_DATA: 'player/UPDATE_STREAM_DETAILS_MODAL_DATA',
    UPDATE_LAYOUT_TEMPLATE_LIST: 'player/UPDATE_LAYOUT_TEMPLATE_LIST',
    UPDATE_PLAYER_FLAGS: 'player/UPDATE_PLAYER_FLAGS',
    CLEAR_PLAYER_STATE: 'player/CLEAR_PLAYER_STATE'
};

export const processNewStream = (authContext: Auth0ContextInterface, input: string, userTitle: string) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            var streamDetailsModalData = getPlayer(getState()).streamDetailsModalData;
            dispatch(action<StreamDetailsModalData>(types.UPDATE_STREAM_DETAILS_MODAL_DATA, { ...streamDetailsModalData, input, isProcessing: true, isReported: false }));

            const requestConfig = await getRequestAuthConfig(authContext);
            const response = await axios.post('/api/streams/analyze', { input }, requestConfig);
            const stream = (response.data as Stream);

            if(stream) {
                processStream(stream);
                streamDetailsModalData = getPlayer(getState()).streamDetailsModalData;
                const newTitle = userTitle ? userTitle : stream?.title;
                dispatch(action<StreamDetailsModalData>(types.UPDATE_STREAM_DETAILS_MODAL_DATA, { ...streamDetailsModalData, input, isProcessing: false, stream, title: newTitle }));
            }
            else {
                streamDetailsModalData = getPlayer(getState()).streamDetailsModalData;
                dispatch(action<StreamDetailsModalData>(types.UPDATE_STREAM_DETAILS_MODAL_DATA, { ...streamDetailsModalData, input, isProcessing: false }));
                dispatch(notify('Not found any valid stream', 'error'));
            }
        }
        catch (error) {
            streamDetailsModalData = getPlayer(getState()).streamDetailsModalData;
            dispatch(action<StreamDetailsModalData>(types.UPDATE_STREAM_DETAILS_MODAL_DATA, { ...streamDetailsModalData, input, isProcessing: false }));
            dispatch(notify('Request failed: cannot process new stream', 'error'));
            console.error(error);
        }
    };
};

export const clearProcessingNewStream = () => action<StreamDetailsModalData>(types.UPDATE_STREAM_DETAILS_MODAL_DATA, null);

export const reportProcessedStream = (authContext: Auth0ContextInterface) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const streamDetailsModalData = getPlayer(getState()).streamDetailsModalData;
            dispatch(action<StreamDetailsModalData>(types.UPDATE_STREAM_DETAILS_MODAL_DATA, { ...streamDetailsModalData, isReported: true }));
    
            const requestConfig = await getRequestAuthConfig(authContext);
            await axios.post('/api/streams/report', { input: streamDetailsModalData.input, stream: streamDetailsModalData.stream }, requestConfig);
            dispatch(notify('Feedback sent', 'success'));
        }
        catch (error) {
            dispatch(notify('Request failed: cannot report processed stream', 'error'));
            console.error(error);
        }
    };
};

export const openStreamDetailModal = (open: boolean) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const streamDetailsModalData = getPlayer(getState()).streamDetailsModalData;
        dispatch(action<StreamDetailsModalData>(types.UPDATE_STREAM_DETAILS_MODAL_DATA, { ...streamDetailsModalData, isModalOpen: open }));
    };
};

export const openEditStreamDetailModal = (streamId: number) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const stream = getStream(getState(), streamId);
        dispatch(action<StreamDetailsModalData>(types.UPDATE_STREAM_DETAILS_MODAL_DATA, { 
            input: stream.url,
            isProcessing: false,
            title: stream.title,
            stream: stream,
            isReported: false,
            isModalOpen: true,
            editStreamId: streamId
        }));
    };
};

export const requestLayoutTemplates = (authContext: Auth0ContextInterface) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const requestConfig = await getRequestAuthConfig(authContext);
            const response = await axios.get('/api/player/layoutTemplates', requestConfig);
            const denormalizedLayoutTemplates = response.data;

            if(!denormalizedLayoutTemplates || denormalizedLayoutTemplates.length <= 0) {
                return;
            }

            const normalizedLayoutTemplates = normalizeLayoutTemplates(denormalizedLayoutTemplates);

            dispatch(action<NormalizedLayoutTemplates>(types.UPDATE_LAYOUT_TEMPLATE_LIST, normalizedLayoutTemplates));

            var multistream = getMultistream(getState());
            if(multistream && (multistream.layout?.components == null || multistream.layout.components.length === 0)) {
                dispatch(changeMultistreamLayout(normalizedLayoutTemplates.entities.layoutTemplates[normalizedLayoutTemplates.result[0]].layout));
            }
        }
        catch (error) {
            dispatch(notify('Request failed: cannot request layout templates', 'error'));
            console.error(error);
        }
    };
};

export const showFullStreamMasks = () => action<PlayerFlags>(types.UPDATE_PLAYER_FLAGS, { fullStreamMasks: true });
export const restoreDefaultStreamMasks = () => action<PlayerFlags>(types.UPDATE_PLAYER_FLAGS, { fullStreamMasks: false });
export const detectedMultistreamChanges = (value: boolean = true) => action<PlayerFlags>(types.UPDATE_PLAYER_FLAGS, { detectedMultistreamChanges: value });
export const saveRequestInProgess = (value: boolean = true) => action<PlayerFlags>(types.UPDATE_PLAYER_FLAGS, { saveRequestInProgess: value });
export const detectedErrorDuringMultistreamFetch = () => action<PlayerFlags>(types.UPDATE_PLAYER_FLAGS, { errorDuringMultistreamFetch: true });
export const clearPlayerState = () => action(types.CLEAR_PLAYER_STATE);
