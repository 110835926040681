import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../site/types/appState';
import Box from '@material-ui/core/Box';
import { showDocumentScrollbars, embedStreams, hideStreamMenus, setUnmuteStreamsOnHover } from '../../actions/multistreamActions';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getMultistreamFlags } from '../../selectors/playerSelectors';
import Container from '@material-ui/core/Container';


const mapStateToProps = (state: AppState) => ({
    showingDocumentScrollbars: getMultistreamFlags(state).showingDocumentScrollbars === true,
    embededStreams: getMultistreamFlags(state).embededStreams === true,
    hiddenStreamMenus: getMultistreamFlags(state).hiddenStreamMenus === true,
    unmuteStreamOnHover: getMultistreamFlags(state).unmuteStreamsOnHover === true
});

const mapDispatchToProps = {
    showDocumentScrollbars,
    embedStreams,
    hideStreamMenus,
    setUnmuteStreamsOnHover
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type MultistreamFlagsSettingsProps = ConnectedProps<typeof connector> & {};

type SettingConfig = {
    label: string;
    value: boolean;
    action: (value: boolean) => void
};

const MultistreamFlagsSettings: FunctionComponent<MultistreamFlagsSettingsProps> = (props) => {
    const { showingDocumentScrollbars, embededStreams, hiddenStreamMenus, unmuteStreamOnHover } = props;
    const { showDocumentScrollbars, embedStreams, hideStreamMenus, setUnmuteStreamsOnHover } = props;

    const settingsConfig : Array<SettingConfig> = [
        { label: 'Unmute one stream on mouse hover', value: unmuteStreamOnHover, action: setUnmuteStreamsOnHover },
        { label: 'Embed streams', value: embededStreams, action: embedStreams },
        { label: 'Hide stream menus', value: hiddenStreamMenus, action: hideStreamMenus },
        { label: 'Show scrollbars', value: showingDocumentScrollbars, action: showDocumentScrollbars }
    ];

    const handleSwitchChange = (action: (value: boolean) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
        action(event.target.checked);
    };

    return (
        <Container maxWidth='md'>
            <Box display='flex' flexDirection='column'>
                {settingsConfig.map(x => 
                    <FormControlLabel label={x.label} key={x.label} control={
                        <Switch checked={x.value} onChange={handleSwitchChange(x.action)} value={x.label} />
                        }
                    />
                )}
            </Box>
        </Container>
    );
}

export default connector(MultistreamFlagsSettings);