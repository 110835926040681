import { abs } from 'mathjs';
import Position from '../../../utils/functions/Position';
import Size from '../../../utils/functions/Size';
import NormalizedEntities from '../../../utils/functions/NormalizedEntities';
import { Component } from '../../types/multistream';
import { SnapPane } from '../../types/snapPane';
import Vector2 from '../../../utils/functions/Vector2';

const snapTolerance = 15;
const snapPaneMarginPixel = 20;


interface Edge {
    coordinate: number;
    start: number;
    end: number;
}

interface ComponentEdges {
    left: Edge,
    right: Edge,
    top: Edge;
    bottom: Edge;
}

interface EdgeCandidates {
    verticalXEdges: Array<Edge>;
    horizontalYEdges: Array<Edge>;
}

interface ClosestEdge {
    edge: Edge;
    difference: number;
}

export interface ComponentSnapResult {
    newPosition: Position;
    newSize: Size;
}

export type SnapMode = 'drag' | 'resize';

export const calculateComponentSnapToComponents = (position: Position, size: Size, otherVisibleComponents: NormalizedEntities<Component>, mode: SnapMode): ComponentSnapResult => {
    position = position.convertUnitToPixel();
    size = size.convertUnitToPixel();

    const edgeCandidates: EdgeCandidates = calculateEdgeCandidates(otherVisibleComponents);
    const referenceComponentEdges = calculateComponentEdges(position, size);

    const leftClosestEdge: ClosestEdge = calculateClosestEdge(referenceComponentEdges.left, edgeCandidates.verticalXEdges, snapTolerance);
    const rightClosestEdge: ClosestEdge = calculateClosestEdge(referenceComponentEdges.right, edgeCandidates.verticalXEdges, snapTolerance);
    const topClosestEdge: ClosestEdge = calculateClosestEdge(referenceComponentEdges.top, edgeCandidates.horizontalYEdges, snapTolerance);
    const bottomClosestEdge: ClosestEdge = calculateClosestEdge(referenceComponentEdges.bottom, edgeCandidates.horizontalYEdges, snapTolerance);


    let closestVerticalXEdge: 'none' | 'left' | 'right' = 'none';
    let closestHorizontalYEdge: 'none' | 'top' | 'bottom' = 'none';

    if (leftClosestEdge != null && leftClosestEdge.difference > 0
        && (rightClosestEdge == null || rightClosestEdge.difference <= 0 || leftClosestEdge.difference <= rightClosestEdge.difference)) {
        closestVerticalXEdge = 'left';
    }
    else if (rightClosestEdge != null) {
        closestVerticalXEdge = 'right';
    }

    if (topClosestEdge != null && topClosestEdge.difference > 0
        && (bottomClosestEdge == null || bottomClosestEdge.difference <= 0 || topClosestEdge.difference <= bottomClosestEdge.difference)) {
        closestHorizontalYEdge = 'top';
    }
    else if (bottomClosestEdge != null) {
        closestHorizontalYEdge = 'bottom';
    }


    if (closestVerticalXEdge === 'none' && closestHorizontalYEdge === 'none') {
        return {
            newPosition: null,
            newSize: null
        };
    }

    if (mode === 'drag') {
        const newX = closestVerticalXEdge === 'left' ? leftClosestEdge.edge.coordinate
            : closestVerticalXEdge === 'right' ? rightClosestEdge.edge.coordinate - size.width
                : position.x;
        const newY = closestHorizontalYEdge === 'top' ? topClosestEdge.edge.coordinate
            : closestHorizontalYEdge === 'bottom' ? bottomClosestEdge.edge.coordinate - size.height
                : position.y;

        return {
            newPosition: new Position(newX, newY, 'px'),
            newSize: null
        };
    }

    if (mode === 'resize') {
        if (closestVerticalXEdge === 'left' || closestHorizontalYEdge === 'top') {
            const newX = closestVerticalXEdge === 'left' ? leftClosestEdge.edge.coordinate
                : position.x
            const newY = closestHorizontalYEdge === 'top' ? topClosestEdge.edge.coordinate
                : position.y;
            const newWidth = closestVerticalXEdge === 'left' ? referenceComponentEdges.right.coordinate - leftClosestEdge.edge.coordinate
                : closestVerticalXEdge === 'right' ? rightClosestEdge.edge.coordinate - referenceComponentEdges.left.coordinate
                    : size.width;
            const newHeight = closestHorizontalYEdge === 'top' ? referenceComponentEdges.bottom.coordinate - topClosestEdge.edge.coordinate
                : closestHorizontalYEdge === 'bottom' ? bottomClosestEdge.edge.coordinate - referenceComponentEdges.top.coordinate
                    : size.height;

            return {
                newPosition: new Position(newX, newY, 'px'),
                newSize: new Size(newWidth, newHeight, 'px')
            };
        }
        else {
            const newWidth = closestVerticalXEdge === 'right' ? rightClosestEdge.edge.coordinate - position.x
                : size.width;
            const newHeight = closestHorizontalYEdge === 'bottom' ? bottomClosestEdge.edge.coordinate - position.y
                : size.height;

            return {
                newPosition: null,
                newSize: new Size(newWidth, newHeight, 'px')
            };
        }
    }
};

const calculateComponentEdges = (position: Position, size: Size): ComponentEdges => {
    return {
        left: { coordinate: position.x, start: position.y, end: position.y + size.height },
        right: { coordinate: position.x + size.width, start: position.y, end: position.y + size.height },
        top: { coordinate: position.y, start: position.x, end: position.x + size.width },
        bottom: { coordinate: position.y + size.height, start: position.x, end: position.x + size.width }
    };
}

const calculateEdgeCandidates = (otherVisibleComponents: NormalizedEntities<Component>): EdgeCandidates => {
    const edgeCandidates: EdgeCandidates = otherVisibleComponents
        .map(x => ({ position: x.position.convertUnitToPixel(), size: x.size.convertUnitToPixel() }))
        .reduce<EdgeCandidates>((candidates: EdgeCandidates, rectangle: { position: Position, size: Size }) => {
            const componentEdges: ComponentEdges = calculateComponentEdges(rectangle.position, rectangle.size);
            candidates.verticalXEdges.push(componentEdges.left);
            candidates.verticalXEdges.push(componentEdges.right);
            candidates.horizontalYEdges.push(componentEdges.top);
            candidates.horizontalYEdges.push(componentEdges.bottom);
            return candidates;
        }, { verticalXEdges: [], horizontalYEdges: [] });

    const windowWidth = Vector2.getViewWidth();
    const windowHeight = Vector2.getViewHeight();
    const windowEdges = calculateComponentEdges(new Position(0, 0, 'px'), new Size(windowWidth, windowHeight, 'px'));

    edgeCandidates.verticalXEdges.push(windowEdges.left);
    edgeCandidates.verticalXEdges.push(windowEdges.right);
    edgeCandidates.horizontalYEdges.push(windowEdges.top);
    edgeCandidates.horizontalYEdges.push(windowEdges.bottom);

    return edgeCandidates
};

const calculateClosestEdge = (referenceEdge: Edge, edges: Array<Edge>, snapTolerance: number): ClosestEdge => {
    let currentClosestEdge: ClosestEdge = null;

    for (const edge of edges) {
        if (referenceEdge.end >= edge.start - snapTolerance && referenceEdge.start <= edge.end + snapTolerance) {
            const edgeDifference = abs(referenceEdge.coordinate - edge.coordinate);

            if (edgeDifference <= snapTolerance && (currentClosestEdge == null || edgeDifference < currentClosestEdge.difference)) {
                currentClosestEdge = {
                    edge: edge,
                    difference: edgeDifference
                }
            }
        }
    }

    return currentClosestEdge;
};

export const calculateSnapPaneForComponent = (pointerPosition: Position, otherVisibleComponents: NormalizedEntities<Component>): Component => {
    if(otherVisibleComponents == null) {
        return null;
    }

    pointerPosition = pointerPosition.convertUnitToPixel();

    const triggeringComponents = otherVisibleComponents.filter(component => {
        const componentPositionPixel = component.position.convertUnitToPixel();
        const componentSizePixel = component.size.convertUnitToPixel();

        return pointerPosition.left >= componentPositionPixel.left + snapPaneMarginPixel
            && pointerPosition.left <= componentPositionPixel.left + componentSizePixel.width - snapPaneMarginPixel
            && pointerPosition.top >= componentPositionPixel.top
            && pointerPosition.top <= componentPositionPixel.top + snapPaneMarginPixel;
    });

    if(triggeringComponents.length <= 0) {
        return null;
    }

    let resultComponent = triggeringComponents.at(0);

    if(triggeringComponents.length > 1) {
        triggeringComponents.forEach((component, index) => {
            if(index > 0 && component.state?.layer != null && (resultComponent.state?.layer == null || component.state.layer > resultComponent.state.layer)) {
                resultComponent = component;
            }
        });
    }

    return resultComponent;
};

export const calculateSnapPaneForPageEdge = (pointerPosition: Position): SnapPane => {
    pointerPosition = pointerPosition.convertUnitToPixel();
    const screenSizePixel = new Size(100, 100, '%').convertUnitToPixel();

    const leftPageEdge = pointerPosition.left <= snapPaneMarginPixel;
    const rightPageEdge = pointerPosition.left >= screenSizePixel.width - snapPaneMarginPixel;
    const topPageEdge = pointerPosition.top <= snapPaneMarginPixel;
    const bottomPageEdge = pointerPosition.top >= screenSizePixel.height - snapPaneMarginPixel;

    if(leftPageEdge && topPageEdge) return { position: new Position(0, 0, '%'), size: new Size(50, 50, '%'), pointerPosition };
    if(leftPageEdge && bottomPageEdge) return { position: new Position(0, 50, '%'), size: new Size(50, 50, '%'), pointerPosition };
    if(rightPageEdge && topPageEdge) return { position: new Position(50, 0, '%'), size: new Size(50, 50, '%'), pointerPosition };
    if(rightPageEdge && bottomPageEdge) return { position: new Position(50, 50, '%'), size: new Size(50, 50, '%'), pointerPosition };
    if(leftPageEdge) return { position: new Position(0, 0, '%'), size: new Size(50, 100, '%'), pointerPosition };
    if(rightPageEdge) return { position: new Position(50, 0, '%'), size: new Size(50, 100, '%'), pointerPosition };
    if(topPageEdge) return { position: new Position(0, 0, '%'), size: new Size(100, 100, '%'), pointerPosition };

    return null;
}