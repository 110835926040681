import { createReducer } from '../../utils/functions/reduxUtils';
import { types } from '../actions/streamsContainerActions';
import {
    getMultistream, getStreamIds, getAllComponents, getFreeComponents,
    getNormalizedMultistream, getComponentIds, getStream, getAllStreams
} from '../selectors/playerSelectors';
import { PlayerState } from '../types/playerState';
import { Stream, Component } from '../types/multistream';
import Position from '../../utils/functions/Position';
import Size from '../../utils/functions/Size';
import { StreamPayload } from '../types/payloadTypes';
import { DenormalizedLayout } from '../types/layoutTemplate';


export const streamsContainerReducer = createReducer<PlayerState>({
    [types.UPDATE_COMPONENT_LAYERS]: draft => updateComponentLayers(draft),
    [types.ADD_STREAM]: (draft, stream: Stream) => addStream(draft, stream),
    [types.REMOVE_STREAM]: (draft, streamId: number) => removeStream(draft, streamId),
    [types.DUPLICATE_STREAM]: (draft, streamId: number) => duplicateStream(draft, streamId),
    [types.EDIT_STREAM]: (draft, payload: StreamPayload<Stream>) => editStream(draft, payload.streamId, payload.data),
    [types.DETACH_STREAM_FROM_MULTICOMPONENT]: (draft, streamId: number) => detachStreamFromMultiComponent(draft, streamId),
    [types.CHANGE_MULTISTREAM_LAYOUT]: (draft, payload: DenormalizedLayout) => changeMultistreamLayout(draft, payload)
});

const updateComponentLayers = (draft: PlayerState) => {
    var components = getAllComponents(draft);

    if (components == null) return;

    var componentsAndAreas = components.map(component => ({
        componentId: component.id,
        area: component.size.width * component.size.height
    }));

    componentsAndAreas.sort((a, b) => (b.area - a.area));
    componentsAndAreas.forEach((value, index) => {
        if (components.get(value.componentId).state.layer !== index + 1) {
            components.get(value.componentId).state.layer = index + 1;
        }
    });
};

const duplicateStream = (draft: PlayerState, streamId: number) => {
    const stream = getStream(draft, streamId);

    if (stream != null) {
        addStream(draft, stream);
    }
};

const addStream = (draft: PlayerState, newStream: Stream) => {
    const normalizedMultistream = getNormalizedMultistream(draft);
    const newStreamComponent = getFreeComponent(draft) || addNewComponent(draft);

    newStream = { ...newStream };
    newStream.id = getNewStreamId(draft);
    newStream.order = newStreamComponent.order;

    if (normalizedMultistream.entities.streams == null) normalizedMultistream.entities.streams = {};

    normalizedMultistream.entities.streams[newStream.id] = newStream;
    getStreamIds(draft).push(newStream.id);
};

const editStream = (draft: PlayerState, editedStreamId: number, newStream: Stream) => {
    const editedStream = getStream(draft, editedStreamId);

    if (editedStream != null && newStream != null) {
        if (newStream.streamType === editedStream.streamType
            && newStream.sourceType === editedStream.sourceType
            && newStream.sourceKey === editedStream.sourceKey) {
            editedStream.title = newStream.title;
            editedStream.thumbnailUrl = newStream.thumbnailUrl;
            editedStream.url = newStream.url;
        }
        else {
            replaceStream(draft, editedStreamId, newStream);
        }
    }
};

const replaceStream = (draft: PlayerState, replacedStreamId: number, newStream: Stream) => {
    const normalizedMultistream = getNormalizedMultistream(draft);
    const replacedStream = getStream(draft, replacedStreamId);

    if (replacedStream != null) {
        newStream = { ...newStream };
        newStream.id = getNewStreamId(draft);
        newStream.order = replacedStream.order;

        normalizedMultistream.entities.streams[newStream.id] = newStream;
        removeStream(draft, replacedStreamId);
        getStreamIds(draft).push(newStream.id);
    }
}

const removeStream = (draft: PlayerState, streamId: number) => {
    getMultistream(draft).streams = getStreamIds(draft).filter(x => x !== streamId);
};

const getNewStreamId = (draft: PlayerState): number => {
    const streamIds = getStreamIds(draft);
    const lowestStreamId = streamIds && Math.min(...streamIds);
    return !streamIds || streamIds.length === 0 || lowestStreamId > 0 ? -1 : lowestStreamId - 1;
};

const getFreeComponent = (draft: PlayerState): Component => {
    const freeComponents = getFreeComponents(draft);
    const lowestComponentOrder = freeComponents && Math.min(...freeComponents.map(x => x.order));
    return lowestComponentOrder && freeComponents?.find(x => x.order === lowestComponentOrder);
};

const addNewComponent = (draft: PlayerState): Component => {
    const allComponents = getAllComponents(draft);
    const lowestComponentId = allComponents && Math.min(...allComponents.ids);
    const highestComponentOrder = allComponents && Math.max(...allComponents.map(x => x.order));
    const newComponentId = !allComponents || allComponents.length === 0 || lowestComponentId > 0 ? -1 : lowestComponentId - 1;
    const newComponentOrder = !allComponents || allComponents.length === 0 ? 1 : highestComponentOrder + 1;
    const newComponent = constructNewComponent(newComponentId, newComponentOrder);

    const normalizedMultistream = getNormalizedMultistream(draft);
    if (normalizedMultistream.entities.components == null) normalizedMultistream.entities.components = {};
    normalizedMultistream.entities.components[newComponent.id] = newComponent;
    getComponentIds(draft).push(newComponent.id);

    return newComponent;
};

const constructNewComponent = (newComponentId: number, newComponentOrder: number): Component => {
    const newComponentSize = new Size(21, 24, '%');
    const positionMargin = new Position(16.5, 12, '%');
    const positionStep = new Position(23, 26, '%');
    const newComponentPosition = calculateNewComponentPositionPercentage(newComponentSize, newComponentOrder, positionMargin, positionStep);

    return {
        id: newComponentId,
        order: newComponentOrder,
        position: newComponentPosition,
        size: newComponentSize,
        state: {}
    };
}

const calculateNewComponentPositionPercentage = (componentSize: Size, componentOrder: number, margin: Position, step: Position): Position => {
    const horizontalStreamsLimit = Math.floor((100 - (2 * margin.x) - componentSize.width) / step.x);
    const verticalStreamsLimit = Math.floor((100 - (2 * margin.y) - componentSize.height) / step.y);
    const newComponentPositionLeftPercentage = margin.x + step.x * ((componentOrder - 1) % (horizontalStreamsLimit + 1));
    const newComponentPositionTopPercentage = margin.y + step.y * (Math.floor((componentOrder - 1) / (verticalStreamsLimit + 1)) % (verticalStreamsLimit + 1));

    return new Position(newComponentPositionLeftPercentage, newComponentPositionTopPercentage, '%');
};

const detachStreamFromMultiComponent = (draft: PlayerState, streamId: number) => {
    const stream = getStream(draft, streamId);
    const nextActiveStreamInMultiComponent = getAllStreams(draft).find(x => x.id !== stream.id && x.order === stream.order);

    nextActiveStreamInMultiComponent.isHidden = false;

    const newStreamComponent = getFreeComponent(draft) || addNewComponent(draft);
    stream.order = newStreamComponent.order;
};

const changeMultistreamLayout = (draft: PlayerState, layout: DenormalizedLayout) => {
    //copy components array
    const layoutComponents = layout?.components == null ? [] : layout.components.map(x => ({ ...x }));

    //add missing new components
    const allStreams = getAllStreams(draft);
    const uniqueStreamOrders = Array.from(new Set<number>(allStreams.map(x => x.order)));
    const numberOfMissingComponents = uniqueStreamOrders.length - layoutComponents.length;
    for (let i = 0; i < numberOfMissingComponents; ++i) {
        const newComponent = constructNewComponent(0, i + 1);
        newComponent.order = 0;
        layoutComponents.push(newComponent);
    }

    //assign component ids
    const alreadyUsedIds = getComponentIds(draft).filter(x => x > 0);
    for (let i = 0; i < layoutComponents.length; ++i) {
        const id = i < alreadyUsedIds.length ? alreadyUsedIds[i] : alreadyUsedIds.length - i - 1;
        layoutComponents[i].id = id;
    }


    //change component orders for streams
    for (let i = 0; i < layoutComponents.length; ++i) {
        if (layoutComponents[i].order <= 0 || !uniqueStreamOrders.some(x => x === layoutComponents[i].order)) {
            const currentComponentOrders = layoutComponents.map(x => x.order);
            const unassignedStreamOrder = uniqueStreamOrders.find(x => !currentComponentOrders.some(y => y === x));

            if (unassignedStreamOrder != null) {
                layoutComponents[i].order = unassignedStreamOrder;
            }
        }
    }

    //assign components to multistream
    const componentIds = layoutComponents.map(x => x.id);
    const componentEntities: { [id: number]: Component } = {};

    for (let i = 0; i < layoutComponents.length; i++) {
        componentEntities[layoutComponents[i].id] = layoutComponents[i];
    }

    const normalizedMultistream = getNormalizedMultistream(draft);
    normalizedMultistream.result.layout.components = componentIds;
    normalizedMultistream.entities.components = componentEntities;
};