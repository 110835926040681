import axios from 'axios';
import { action } from '../../utils/functions/reduxUtils';
import { updateComponentLayers } from './streamsContainerActions';
import { getNormalizedMultistream, getAllStreams, getMultistream } from '../selectors/playerSelectors';
import { NormalizedMultistream, MultistreamFlags } from '../types/multistream';
import { normalizeMultistream, denormalizeMultistream } from './helpers/multistreamProcessHelper';
import { notify } from '../../site/actions/siteActions';
import { AppState } from '../../site/types/appState';
import { requestLayoutTemplates, clearPlayerState, detectedErrorDuringMultistreamFetch, openStreamDetailModal } from './playerActions';
import { AppDispatch } from '../../site/types/actionTypes';
import { requestNextMultiVideoStreamsIfNeeded } from './streamActions';
import { detectedMultistreamChanges, saveRequestInProgess } from './playerActions';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getRequestAuthConfig } from '../../utils/functions/axiosUtils';
import { MultistreamState } from '../types/multistreamState';

export const types = {
    SET_NORMALIZED_MULTISTREAM: 'multistream/SET_NORMALIZED_MULTISTREAM',
    EDIT_MULTISTREAM_GENERAL_INFO: 'multistream/EDIT_MULTISTREAM_GENERAL_INFO',
    SET_MULTISTREAM_IS_OWNER: 'multistream/SET_MULTISTREAM_IS_OWNER',
    UPDATE_MULTISTREAM_IDS: 'multistream/UPDATE_MULTISTREAM_IDS',
    UPDATE_MULTISTREAM_FLAGS: 'multistream/UPDATE_MULTISTREAM_FLAGS',
    UPDATE_MULTISTREAM_STATE: 'multistream/UPDATE_MULTISTREAM_STATE'
};

export const fetchMultistream = (authContext: Auth0ContextInterface, apiUrl: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(clearPlayerState());
            const requestConfig = await getRequestAuthConfig(authContext);
            const response = await axios.get(apiUrl, requestConfig);
            var denormalizedMultistream = response.data;
            var normalizedMultistream = normalizeMultistream(denormalizedMultistream);
            dispatch(setMultistream(authContext, normalizedMultistream));
            dispatch(requestLayoutTemplates(authContext));

            if (normalizedMultistream?.result?.isOwner && normalizedMultistream.result.streams?.length === 0) {
                dispatch(openStreamDetailModal(true));
            }
        }
        catch (error) {
            dispatch(notify('Request failed: cannot fetch multistream', 'error'));
            dispatch(detectedErrorDuringMultistreamFetch());
            console.error(error);
        }
    };
};

export const setMultistream = (authContext: Auth0ContextInterface, normalizedMultistream: NormalizedMultistream) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(action<NormalizedMultistream>(types.SET_NORMALIZED_MULTISTREAM, normalizedMultistream));
        dispatch(updateComponentLayers());

        getAllStreams(getState())?.forEach(x => {
            if (x.streamType === 'MultiVideo') {
                dispatch(requestNextMultiVideoStreamsIfNeeded(authContext, x.sourceType, x.sourceKey));
            }
        });
    };
};

export const saveMultistream = (authContext: Auth0ContextInterface) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            dispatch(saveRequestInProgess(true));
            const normalizedMultistreamRequest = getNormalizedMultistream(getState());
            const denormalizedMultistreamRequest = denormalizeMultistream(normalizedMultistreamRequest);

            const requestConfig = await getRequestAuthConfig(authContext);
            const response = await axios.post('/api/multistreams/edit', denormalizedMultistreamRequest, requestConfig);
            const denormalizedMultistreamResponse = response.data;
            const normalizedMultistreamResponse = normalizeMultistream(denormalizedMultistreamResponse);
            dispatch(saveRequestInProgess(false));
            dispatch(detectedMultistreamChanges(false));
            dispatch(action<NormalizedMultistream>(types.UPDATE_MULTISTREAM_IDS, normalizedMultistreamResponse));
            dispatch(notify('Saved multistream', 'success'));
        }
        catch (error) {
            dispatch(saveRequestInProgess(false));
            dispatch(notify('Request failed: cannot save multistream', 'error'));
            console.error(error);
        }
    }
};

export const editMultistreamGeneralInfo = (title: string, description: string, isPrivate: boolean) => {
    return (dispatch: AppDispatch) => {
        dispatch(action(types.EDIT_MULTISTREAM_GENERAL_INFO, { title, description, isPrivate }));
        dispatch(detectedMultistreamChanges());
    };
}

export const recheckIfCurrentUserIsMultistreamOwner = (authContext: Auth0ContextInterface) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const multistream = getMultistream(getState());
            if (!multistream || multistream.isOwner) {
                return;
            }

            const requestConfig = await getRequestAuthConfig(authContext);
            const response = await axios.get('/api/multistreams/isOwner/' + multistream.key, requestConfig);
            const isMultistreamOwner = response.data === true;
            dispatch(action<boolean>(types.SET_MULTISTREAM_IS_OWNER, isMultistreamOwner));
        }
        catch (error) {
            dispatch(notify('Request failed: cannot recheck multistream owner', 'error'));
            console.error(error);
        }
    }
};

export const updateSynchronizationBaseTime = (latestSynchronizationBaseMs: number, synchronizationBaseSnapshotTimeMs: number) =>
    action<MultistreamState>(types.UPDATE_MULTISTREAM_STATE, { latestSynchronizationBaseMs, synchronizationBaseSnapshotTimeMs });

export const showDocumentScrollbars = (value: boolean = true) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<MultistreamFlags>(types.UPDATE_MULTISTREAM_FLAGS, { showingDocumentScrollbars: value }));
        dispatch(detectedMultistreamChanges());
    };
}

export const embedStreams = (value: boolean = true) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<MultistreamFlags>(types.UPDATE_MULTISTREAM_FLAGS, { embededStreams: value }));
        dispatch(detectedMultistreamChanges());
    };
}

export const hideStreamMenus = (value: boolean = true) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<MultistreamFlags>(types.UPDATE_MULTISTREAM_FLAGS, { hiddenStreamMenus: value }));
        dispatch(detectedMultistreamChanges());
    };
}

export const setUnmuteStreamsOnHover = (value: boolean = true) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<MultistreamFlags>(types.UPDATE_MULTISTREAM_FLAGS, { unmuteStreamsOnHover: value }));
        dispatch(detectedMultistreamChanges());
    };
}
