import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import Fade from '@material-ui/core/Fade';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { AppState } from '../../../site/types/appState';
import { getComponent, getMultistreamFlags } from '../../selectors/playerSelectors';

export type StreamSettingsOption = {
    title: string;
    icon: JSX.Element;
    action?: (streamId: number, componentId: number) => void;
    hidden?: boolean;
};

type OwnProps = {
    streamId: number;
    componentId: number;
    streamSettingsOptions: Array<StreamSettingsOption>;
};

const styles = (theme: Theme) => createStyles({
    buttonPanel: {
        position: 'fixed',
        top: theme.spacing(1),
        right: theme.spacing(1)
    },
    optionTitle: {
        marginLeft: theme.spacing(1)
    }
});

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
    component: getComponent(state, ownProps.componentId),
    hiddenStreamMenus: getMultistreamFlags(state).hiddenStreamMenus === true
});

const connector = connect(mapStateToProps);

type StreamSettingsBaseProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & OwnProps;

const StreamSettingsBase: FunctionComponent<StreamSettingsBaseProps> = (props) => {
    const { classes } = props;
    const { streamId, componentId, streamSettingsOptions } = props;
    const { component, hiddenStreamMenus } = props;

    const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);
    const [isMouseOverButton, setMouseOverButton] = React.useState(false);
    const isMenuOpen = Boolean(menuAnchorElement);

    const isFrameActive = component.state.isFrameActive === true;
    const isHidden = component.state.showSettings !== true || hiddenStreamMenus;

    const stopEventPropagation = (event: React.SyntheticEvent) => {
        event.stopPropagation();
    };

    const handleMenuOpenClick = (event: React.SyntheticEvent) => {
        setMenuAnchorElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorElement(null);
    };

    const handleMenuItemClick = (streamSettingsOption: StreamSettingsOption) => {
        handleMenuClose();
        streamSettingsOption.action?.(streamId, componentId);
    };

    return (
        <div onMouseDown={stopEventPropagation} onMouseEnter={() => setMouseOverButton(true)} onMouseLeave={() => setMouseOverButton(false)}>
            <Fade in={!isFrameActive && (!isHidden || isMouseOverButton)} timeout={300}>
                <Tooltip title='Stream menu' aria-label='menu'>
                    <Fab className={classes.buttonPanel} size='small' color="primary" onClick={handleMenuOpenClick}>
                        <MenuIcon />
                    </Fab>
                </Tooltip>
            </Fade>

            <Menu anchorEl={menuAnchorElement} open={isMenuOpen} onClose={handleMenuClose} keepMounted>
                {streamSettingsOptions.map((streamOption, index) => (streamOption.hidden == null || !streamOption.hidden) &&
                    <MenuItem key={index} onClick={() => handleMenuItemClick(streamOption)}>
                        {streamOption.icon}
                        <Typography className={classes.optionTitle}>{streamOption.title}</Typography>
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default connector(withStyles(styles)(StreamSettingsBase));