import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setStreamDefaultMaskRectangle } from '../../../actions/streamActions';
import StopIFrameFlash from '../../../utils/StopIFrameFlash';
import ReactPlayerStream from './ReactPlayerStream';

const mapDispatchToProps = {
    setStreamDefaultMaskRectangle
};

const connector = connect(null, mapDispatchToProps);

type TwitchStreamProps = ConnectedProps<typeof connector> & {
    streamId: number;
    sourceType: string;
    sourceKey: string;
};

const TwitchStream: FunctionComponent<TwitchStreamProps> = (props) => {
    const { streamId, sourceType, sourceKey } = props;
    const { setStreamDefaultMaskRectangle } = props;

    React.useEffect(() => {
        streamId !== 0 && setStreamDefaultMaskRectangle(streamId, { height: 'calc(100% - 50px)' });
    }, [streamId, setStreamDefaultMaskRectangle]);

    let twitchUrl;

    if(sourceType === 'video') {
        twitchUrl = 'twitch.tv/videos/' + sourceKey;
    }
    else {
        twitchUrl = 'twitch.tv/' + sourceKey;
    }

    return (
        <StopIFrameFlash onLoadPropertyName='onReady'>
            <ReactPlayerStream streamId={streamId} url={twitchUrl} />
        </StopIFrameFlash>
    );
}

export default connector(TwitchStream);