import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';

const styles = () => createStyles({
    iframe: {
        border: 'none',
        backgroundColor: 'white'
    }
});

type WebsiteStreamProps = WithStyles<typeof styles> & {
    sourceKey: string;
};

const WebsiteStream: FunctionComponent<WebsiteStreamProps> = (props) => {
    const { classes } = props;
    const { sourceKey } = props;

    return (
        <iframe title={sourceKey} src={sourceKey} className={classes.iframe} width='100%' height='100%' allowFullScreen />
    );
}

export default withStyles(styles)(WebsiteStream);