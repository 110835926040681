import Vector2, { VectorUnit } from './Vector2';

export default class Position {
    private readonly _vector: Vector2;

    static fromVector(vector: Vector2): Position {
        return new Position(vector.x, vector.y, vector.unit);
    }

    constructor(left: number, top: number, unit: VectorUnit) {
        this._vector = new Vector2(left, top, unit);
    }

    public clone(): Position {
        return new Position(this.left, this.top, this.unit);
    }

    public get left(): number {
        return this._vector.x;
    }

    public get top(): number {
        return this._vector.y;
    }

    public get x(): number {
        return this.left;
    }

    public get y(): number {
        return this.top;
    }

    public convertUnitToPercent(): Position {
        if(this.unit === '%') return this;

        var newVector = this._vector.clone();
        newVector.convertUnitToPercent();
        return Position.fromVector(newVector);
    }

    public convertUnitToPixel(): Position {
        if(this.unit === 'px') return this;

        var newVector = this._vector.clone();
        newVector.convertUnitToPixel();
        return Position.fromVector(newVector);
    }

    private get unit(): VectorUnit {
        return this._vector.unit;
    }
}