import NormalizedEntities, { EntitiesObjectType } from '../../utils/functions/NormalizedEntities';
import { NormalizedMultistream, Multistream, Layout, Stream, Component, MultistreamFlags } from '../types/multistream';
import { AppState } from '../../site/types/appState';
import { PlayerState } from '../types/playerState';
import { MultiVideoStreamsQueue } from '../types/multiVideoStreams';
import { PlayerFlags } from '../types/playerFlags';
import { LayoutTemplate } from '../types/layoutTemplate';

type State = AppState | PlayerState;

const isPlayerState = (state: State): state is PlayerState => {
    return !state.hasOwnProperty('player');
}

export const getPlayer = (state: State): PlayerState => {
    return isPlayerState(state) ? state : state.player;
};

export const getNormalizedMultistream = (state: State): NormalizedMultistream => {
    return getPlayer(state)?.normalizedMultistream;
};

export const getMultistream = (state: State): Multistream => {
    return getNormalizedMultistream(state)?.result;
};

export const getMultistreamFlags = (state: State): MultistreamFlags => {
    return getMultistream(state)?.flags || {};
};

export const getStreamIds = (state: State): number[] => {
    return getMultistream(state)?.streams;
}

export const getLayout = (state: State): Layout => {
    return getMultistream(state)?.layout;
}

export const getComponentIds = (state: State): number[] => {
    return getLayout(state)?.components;
}

export const getStreamsObject = (state: State): EntitiesObjectType<Stream> => {
    const normalizedMultistream = getNormalizedMultistream(state);
    const streamsObject = normalizedMultistream?.entities.streams;
    return streamsObject || {};
}

export const getComponentsObject = (state: State): EntitiesObjectType<Component> => {
    const normalizedMultistream = getNormalizedMultistream(state);
    const componentsObject = normalizedMultistream?.entities.components;
    return componentsObject || {};
}

export const getStream = (state: State, streamId: number): Stream => {
    const streamsObject = getStreamsObject(state);
    return streamsObject && streamsObject[streamId];
};

export const getComponent = (state: State, componentId: number): Component => {
    const componentsObject = getComponentsObject(state);
    return componentsObject && componentsObject[componentId];
};

export const getAllStreams = (state: State): NormalizedEntities<Stream> => {
    const streamIds = getStreamIds(state);
    const streamsObject = getStreamsObject(state);

    const streams = streamIds && streamsObject && new NormalizedEntities<Stream>(streamIds, streamsObject);
    return streams
};

export const getVisibleStreams = (state: State): NormalizedEntities<Stream> => {
    const allStreams = getAllStreams(state);
    const visibleStreams = allStreams && allStreams.filter(x => !x.isHidden);
    return visibleStreams;
};

export const getAllComponents = (state: State): NormalizedEntities<Component> => {
    const componentIds = getComponentIds(state);
    const componentsObject = getComponentsObject(state);

    const components = componentIds && componentsObject && new NormalizedEntities<Component>(componentIds, componentsObject);
    return components
};

type StreamsComponentPair = {
    streamIds: Array<number>;
    componentId: number;
};

export const getVisibleStreamsComponentPairs = (state: State): Array<StreamsComponentPair> => {
    const allStreams = getAllStreams(state);
    const allComponents = getAllComponents(state);

    if (!allStreams || !allComponents) {
        return null;
    }

    const groupedStreams = allStreams.ids.reduce<{ [streamOrder: number]: Array<Stream> }>((groups, streamId) => {
        const stream = allStreams.get(streamId);
        groups[stream.order] = groups[stream.order] || [];
        groups[stream.order].push(stream);
        return groups;
    }, {});

    const result: Array<StreamsComponentPair> = Object.entries(groupedStreams)
        .filter(([_, streams]) => {
            return streams.some(x => !x.isHidden)
        })
        .map(([streamOrder, streams]) => {
            const componentForStreams = allComponents?.find(component => component.order === Number(streamOrder))

            if (componentForStreams == null)
                throw new Error('Not found component for stream');

            return {
                streamIds: streams.map(x => x.id),
                componentId: componentForStreams.id
            } as StreamsComponentPair;
        });

    return result;
};

export const getVisibleComponents = (state: State): NormalizedEntities<Component> => {
    const allComponents = getAllComponents(state);
    const visibleStreams = getVisibleStreams(state);
    const visibleComponents = visibleStreams && allComponents?.filter(x => visibleStreams.find(y => y.order === x.order && !y.isHidden) != null);
    return visibleComponents;
};

export const getFreeComponents = (state: State): NormalizedEntities<Component> => {
    const allStreams = getAllStreams(state);
    const allComponents = getAllComponents(state);

    const freeComponents = allStreams && allComponents?.filter(component => allStreams.find(stream => stream.order === component.order) == null);
    return freeComponents;
};

export const getMultiVideoStreamsQueue = (state: State, type: string, key: string): MultiVideoStreamsQueue => {
    return getPlayer(state)?.multiVideoStreams?.queues?.[type]?.[key];
};

export const getPlayerFlags = (state: State): PlayerFlags => {
    return getPlayer(state)?.playerFlags;
};

export const getStreamWithDifferentSourceType = (state: State, streamId: number, differentSourceType: string): Stream => {
    const stream = getStream(state, streamId);
    const allStreams = getAllStreams(state);

    const differentStream = stream && allStreams?.find(x => 
        x.id !== stream.id 
        && x.streamType === stream.streamType 
        && x.sourceKey === stream.sourceKey 
        && x.sourceType === differentSourceType);
    return differentStream;
};

export const getLayoutTemplates = (state: State): NormalizedEntities<LayoutTemplate> => {
    const normalizedLayoutTemplates = getPlayer(state)?.normalizedLayoutTemplates;
    return normalizedLayoutTemplates && new NormalizedEntities<LayoutTemplate>(normalizedLayoutTemplates.result, normalizedLayoutTemplates.entities.layoutTemplates);
};

export const getSyncStreams = (state: State): NormalizedEntities<Stream> => {
    const visibleStreams = getVisibleStreams(state);
    const syncStreams = visibleStreams && visibleStreams.filter(stream => stream.state.synchronizationSupport && stream.synchronization != null);

    return syncStreams;
};

export const generateCalculateRequestedSynchronizationTimeMsFunction = (state: State, streamId: number): ((currentTime: number) => number) => {
    const multistreamState = getMultistream(state)?.state;
    const stream = getStream(state, streamId);

    return multistreamState && stream 
        && stream.state.synchronizationSupport && stream.synchronization != null && stream.state.isSynchronizationRequested 
        && multistreamState.latestSynchronizationBaseMs != null
        ? (currentTime: number) => {
            return multistreamState.synchronizationBaseSnapshotTimeMs != null
                ? multistreamState.latestSynchronizationBaseMs + stream.synchronization + (currentTime - multistreamState.synchronizationBaseSnapshotTimeMs)
                : multistreamState.latestSynchronizationBaseMs + stream.synchronization;
        }
        : null; 
};