import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { getPlayer } from '../selectors/playerSelectors';
import { AppState } from '../../site/types/appState';
import Box from '@material-ui/core/Box';
import { Transition } from 'react-transition-group';

const styles = (theme: Theme) => createStyles({
    root: {
        position: 'absolute',
        background: '#999',
        borderStyle: 'groove',
        borderWidth: 2,
        zIndex: 98
    }
});

const mapStateToProps = (state: AppState) => ({
    snapPane: getPlayer(state).snapPane
 });
 
const connector = connect(mapStateToProps);

const snapPaneMarginPixel = 5;
const duration = 300;

type SnapPaneProps = WithStyles<typeof styles> & ConnectedProps<typeof connector>;

const SnapPane: FunctionComponent<SnapPaneProps> = (props) => {
    const { classes } = props;
    const { snapPane } = props;

    if(!snapPane) {
        return null;
    }

    const pixelPosition = snapPane.position.convertUnitToPixel();
    const percentSize = snapPane.size.convertUnitToPercent();
    const pointerPixel = snapPane.pointerPosition.convertUnitToPixel();

    const left = pixelPosition.left + snapPaneMarginPixel;
    const top = pixelPosition.top + snapPaneMarginPixel;
    const width = `calc(${percentSize.width}% - ${2 * snapPaneMarginPixel}px)`;
    const height = `calc(${percentSize.height}% - ${2 * snapPaneMarginPixel}px)`;

    const initialStyle: any = {
        transition: `opacity ${duration}ms, left ${duration}ms, top ${duration}ms, width ${duration}ms, height ${duration}ms`,
        opacity: 0,
        left: pointerPixel.left,
        top: pointerPixel.top,
        width: 0,
        height: 0
    };

    const targetStyle: any = {
        opacity: 0.2,
        left: left,
        top: top,
        width: width,
        height: height
    }

    const transitionStyles: any = {
        entering: targetStyle,
        entered:  targetStyle
    };

    return (
        <Transition in timeout={duration} appear>
            {state => (
                <Box className={classes.root} style={{
                        ...initialStyle,
                        ...transitionStyles[state]
                    }} /> 
            )}
        </Transition>
    );
}

export default connector(withStyles(styles)(SnapPane));