import axios from 'axios';
import { action } from '../../utils/functions/reduxUtils';
import { AppDispatch } from '../types/actionTypes';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getRequestAuthConfig } from '../../utils/functions/axiosUtils';
import { AppState } from '../types/appState';
import { getCardsData } from '../selectors/siteSelectors';
import { CardsData } from '../types/cardsData';

export const types = {
    UPDATE_CARDS: 'cards/UPDATE_CARDS',
    CLEAR_CARDS: 'cards/CLEAR_CARDS',
    CLEAR_ALL_CARDS: 'cards/CLEAR_ALL_CARDS'
};


export const fetchCards = (authContext: Auth0ContextInterface, id: string, url: string, forwardCursor: string = null) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        try {
            const cardsData = getCardsData(getState(), id);
            if(cardsData != null && cardsData.state === 'loading' && cardsData.cancelToken != null) {
                cardsData.cancelToken.cancel();
            }

            const cancelToken = axios.CancelToken.source();
            dispatch(action<CardsData>(types.UPDATE_CARDS, { id, url, state: 'loading', cancelToken: cancelToken }));

            const requestConfig = await getRequestAuthConfig(authContext);
            const response = await axios.get(url, { 
                params: { forwardCursor: forwardCursor },
                cancelToken: cancelToken.token,
                ...requestConfig 
            });
            const data = response.data;
            const cards = data.data;
            const loadedAll = !data.cursor || !cards || cards.length < 1;

            if (loadedAll) {
                dispatch(action<CardsData>(types.UPDATE_CARDS, {
                    id,
                    url,
                    cards: cards ? cards : [],
                    forwardCursor: null,
                    state: 'loadedAll'
                }));
            }
            else {
                dispatch(action<CardsData>(types.UPDATE_CARDS, {
                    id,
                    url,
                    cards,
                    forwardCursor: data.cursor,
                    state: 'loaded'
                }));
            }
        }
        catch (error) {
            if(!axios.isCancel(error)) {
                dispatch(action<CardsData>(types.UPDATE_CARDS, { id, url, state: 'error' }));
                console.error(error);
            }
        }
    };
};

export const clearCards = (id: string) => action<string>(types.CLEAR_CARDS, id);
export const clearAllCards = () => action<string>(types.CLEAR_ALL_CARDS);

export const refreshCards = (authContext: Auth0ContextInterface, id: string) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(clearCards(id));

        const cardsData = getCardsData(getState(), id);
        if(cardsData) {
            dispatch(fetchCards(authContext, id, cardsData.url));
        }
    };
};