import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import Stream from './stream/Stream';
import MultiComponent from './stream/MultiComponent';
import { getVisibleStreamsComponentPairs } from '../selectors/playerSelectors';
import { AppState } from '../../site/types/appState';

const styles = (theme: Theme) => createStyles({
    streamsContainer: {
        height: '100vh'
    },
    debugTextBox: {
        color: 'white'
    },
    red: {
        backgroundColor: 'Salmon',
        border: '2px solid yellow'
    },
    pink: {
        backgroundColor: 'pink',
        border: '2px solid #53ff1a'
    },
    yellow: {
        backgroundColor: 'yellow',
        border: '2px solid red'
    }
});

const mapStateToProps = (state: AppState) => ({
    visibleStreamComponentPairs: getVisibleStreamsComponentPairs(state)
});

const connector = connect(mapStateToProps);

type StreamsContainerProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & {
    onMouseMove?: () => void;
};

const StreamsContainer: FunctionComponent<StreamsContainerProps> = (props) =>  {
    const { classes } = props;
    const { onMouseMove } = props;
    const { visibleStreamComponentPairs } = props;
    
    const streamComponents = visibleStreamComponentPairs && visibleStreamComponentPairs.map((streamComponentPair) => {
        const firstStreamId = streamComponentPair.streamIds[0];

        if(streamComponentPair.streamIds.length === 1) {
            return <Stream key={firstStreamId} streamId={firstStreamId} componentId={streamComponentPair.componentId} />;
        }
        else {
            return <MultiComponent key={firstStreamId} streamIds={streamComponentPair.streamIds} componentId={streamComponentPair.componentId} />;
        }
    });

    return (
        <div className={classes.streamsContainer} onMouseMove={onMouseMove}>
            {streamComponents}
        </div>
    );
};

export default connector(withStyles(styles)(StreamsContainer));