import React, { FunctionComponent } from 'react';
import { Prompt } from 'react-router-dom';


type UnsavedChangesAlertProps =  {
    hasUnsavedChanges: boolean;
    alertMessage: string;
};

const UnsavedChangesAlert: FunctionComponent<UnsavedChangesAlertProps> = (props) => {
    const { hasUnsavedChanges, alertMessage } = props;

    React.useEffect(() => {
        window.onbeforeunload = hasUnsavedChanges && (() => alertMessage);

        return () => window.onbeforeunload = null;
    }, [hasUnsavedChanges, alertMessage]);

    return (
        <Prompt
            when={hasUnsavedChanges}
            message={alertMessage}
          />
    );
}

export default UnsavedChangesAlert;