import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { saveMultistream, recheckIfCurrentUserIsMultistreamOwner } from '../../actions/multistreamActions';
import { Link as RouterLink } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import Fade from '@material-ui/core/Fade';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import ExitIcon from '@material-ui/icons/ExitToApp';
import Drawer from '@material-ui/core/Drawer';
import { IconButton, Button, Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import GeneralInformationSettings from './GeneralInformationSettings';
import SelectStreamsSettings from './SelectStreamsSettings';
import { openStreamDetailModal } from '../../actions/playerActions';
import { AppState } from '../../../site/types/appState';
import { getPlayerFlags, getMultistreamFlags } from '../../selectors/playerSelectors';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import ButtonProgress from '../../../utils/components/ButtonProgress';
import UnsavedChangesAlert from '../../../utils/components/UnsavedChangesAlert';
import MultistreamFlagsSettings from './MultistreamFlagsSettings';
import DocumentScrollbars from '../../../utils/components/DocumentScrollbars';
import LayoutSettings from './LayoutSettings';
import SynchronizationSettings from './SynchronizationSettings';

const multistreamSettingsPanels: Array<{
    title: string;
    description?: string;
    component: JSX.Element;
}> = [
        { title: 'General information', description: 'Change multistream title and visibility', component: <GeneralInformationSettings /> },
        { title: 'Settings', description: 'Change multistream settings', component: <MultistreamFlagsSettings /> },
        { title: 'Streams', description: 'Show or hide available streams', component: <SelectStreamsSettings /> },
        { title: 'Layout', description: 'Change current multistream layout', component: <LayoutSettings /> },
        { title: 'Synchronization', description: 'Synchronize multiple streams', component: <SynchronizationSettings /> }
    ];

const styles = (theme: Theme) => createStyles({
    buttonsPanel: {
        position: 'fixed',
        top: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 1000
    },
    button: {
        marginRight: theme.spacing(1),
        '&$disabledButton': {
            backgroundColor: 'rgba(179, 179, 179, 1)',
        }
    },
    disabledButton: {},
    drawerPaper: {
        display: 'grid'
    },
    drawerContainer: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowX: 'hidden'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1, 1, 1, 3)
    },
    drawerHeaderName: {
        flexGrow: 1
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 'bold',
        flexBasis: '50%',
        flexShrink: 0
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        width: '100%',
        color: theme.palette.text.secondary
    },
    drawerFooter: {
        padding: theme.spacing(1, 1, 3, 3)
    },
    saveProgress: {
        position: 'absolute'
    }
});

const mapStateToProps = (state: AppState) => ({
    detectedMultistreamChanges: getPlayerFlags(state).detectedMultistreamChanges === true,
    saveRequestInProgess: getPlayerFlags(state).saveRequestInProgess === true,
    showingDocumentScrollbars: getMultistreamFlags(state).showingDocumentScrollbars === true,
    embededStreams: getMultistreamFlags(state).embededStreams === true,
    hiddenStreamMenus: getMultistreamFlags(state).hiddenStreamMenus === true
});

const mapDispatchToProps = {
    saveMultistream,
    openStreamDetailModal,
    recheckIfCurrentUserIsMultistreamOwner
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type MultistreamSettingsProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & {
    isMouseMoving?: boolean;
    isOwner: boolean;
};

const MultistreamSettings: FunctionComponent<MultistreamSettingsProps> = (props) => {
    const { classes } = props;
    const { isMouseMoving, isOwner } = props;
    const { detectedMultistreamChanges, saveRequestInProgess, showingDocumentScrollbars, embededStreams, hiddenStreamMenus } = props;
    const { saveMultistream, openStreamDetailModal, recheckIfCurrentUserIsMultistreamOwner } = props;
    const authContext = useAuth0();
    const [isDrawerOpen, toggleDrawer] = React.useState(false);
    const [expandedPanel, setExpandedPanel] = React.useState(-1);
    const [isSettingsHidden, setSettingsHidden] = React.useState(false);
    const [isMouseOver, setMouseOver] = React.useState(false);

    React.useEffect(() => {
        if (isMouseMoving) {
            setSettingsHidden(false);
        }
        else {
            const timer = setTimeout(() => {
                setSettingsHidden(true);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [isMouseMoving]);

    const handleExpandedPanelChange = (panel: number) => (_: any, isExpanded: boolean) => {
        setExpandedPanel(isExpanded ? panel : -1);
    };

    const onSaveClick = () => {
        if (!saveRequestInProgess) {
            saveMultistream(authContext);
        }
    };

    return (
        <div>
            <Fade in={!isSettingsHidden || isMouseOver} timeout={300}>
                <div className={classes.buttonsPanel} onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
                    <Tooltip title='Multistream settings' aria-label='settings'>
                        <Fab className={classes.button} size='medium' color='primary' onClick={() => toggleDrawer(true)}>
                            <SettingsIcon />
                        </Fab>
                    </Tooltip>
                    {!embededStreams && !hiddenStreamMenus &&
                        <Tooltip title='Add new stream' aria-label='add'>
                            <Fab className={classes.button} size='medium' color='secondary' onClick={() => openStreamDetailModal(true)}>
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    }
                    {isOwner &&
                        <Tooltip title='Save multistream' aria-label='save'>
                            <Box py={2} component='span'>
                                <Fab className={classes.button} classes={{ disabled: classes.disabledButton }} size='medium' color='secondary'
                                    onClick={onSaveClick} disabled={!detectedMultistreamChanges}>
                                    <SaveIcon />
                                    {saveRequestInProgess && <CircularProgress size={60} className={classes.saveProgress} />}
                                </Fab>
                            </Box>
                        </Tooltip>
                    }
                </div>
            </Fade>

            <Drawer
                anchor='left'
                open={isDrawerOpen}
                onClose={() => toggleDrawer(false)}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerContainer}>
                    <div>
                        <div className={classes.drawerHeader}>
                            <Typography className={classes.drawerHeaderName} variant='h4'>Multistream settings</Typography>
                            <IconButton onClick={() => toggleDrawer(false)}><CloseIcon /></IconButton>
                        </div>

                        {multistreamSettingsPanels.map((settingsPanel, index) =>
                            <Accordion key={index} expanded={expandedPanel === index} onChange={handleExpandedPanelChange(index)}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.heading}>{settingsPanel.title}</Typography>
                                    <Typography className={classes.secondaryHeading}>{settingsPanel.description}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {settingsPanel.component}
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </div>
                    <Box display='flex' justifyContent='space-between' p={3} whiteSpace='nowrap'>
                        <Box whiteSpace='nowrap'>
                            <Button
                                variant='contained'
                                color='secondary'
                                startIcon={<ExitIcon />}
                                component={RouterLink} to='/browse'
                            >
                                Exit multistream
                            </Button>
                        </Box>
                        <Box ml={3} minWidth={0}>
                            <LoginButton recheckIfCurrentUserIsMultistreamOwner={recheckIfCurrentUserIsMultistreamOwner} />
                        </Box>
                    </Box>
                </div>
            </Drawer>

            <DocumentScrollbars hide={!showingDocumentScrollbars} />
            <UnsavedChangesAlert hasUnsavedChanges={isOwner && detectedMultistreamChanges} alertMessage='Do you really want to exit? All unsaved changes will be lost.' />
        </div>
    );
};

type LoginButtonProps = {
    recheckIfCurrentUserIsMultistreamOwner: (authContext: Auth0ContextInterface) => void;
};

const LoginButton: FunctionComponent<LoginButtonProps> = (props) => {
    const { recheckIfCurrentUserIsMultistreamOwner } = props;
    const authContext = useAuth0();

    React.useEffect(() => {
        if (authContext.isAuthenticated) {
            recheckIfCurrentUserIsMultistreamOwner(authContext);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authContext.isAuthenticated])

    if (authContext.isAuthenticated) {
        return (
            <Box mt={0.5}>
                <Typography variant='subtitle1' noWrap>
                    Logged as {authContext.user.nickname}
                </Typography>
            </Box>
        );
    }
    else {
        return (
            <Button variant='contained' color='primary' onClick={authContext.loginWithPopup} disabled={authContext.isLoading}>
                Login
                {authContext.isLoading && <ButtonProgress />}
            </Button>
        );
    }
};

export default connector(withStyles(styles)(MultistreamSettings));