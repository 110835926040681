import React, { FunctionComponent, ReactElement } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { getAllStreams } from '../../selectors/playerSelectors';
import { hideStream } from '../../actions/streamActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import { AppState } from '../../../site/types/appState';
import LanguageIcon from '@material-ui/icons/Language';
import YouTubeIcon from '@material-ui/icons/YouTube';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import VideocamIcon from '@material-ui/icons/Videocam';
import TwitterIcon from '@material-ui/icons/Twitter';
import MdiIcon from '@mdi/react';
import { mdiTwitch } from '@mdi/js';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const mapStreamTypeToIcon: { [key: string]: ReactElement } = {
    'Youtube': <YouTubeIcon />,
    'Twitch': <MdiIcon path={mdiTwitch} size={1} color='#757575' />,
    'ReactPlayer': <VideocamIcon />,
    'Twitter': <TwitterIcon />,
    'VideoFile': <VideocamIcon />,
    'AudioFile': <AudiotrackIcon />,
    'Website': <LanguageIcon />,
    'Default': <LanguageIcon />
};

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%'
    },
    listItemIcon: {
        minWidth: '0px',
        marginRight: theme.spacing(2)
    },
    listItemText: {
        marginRight: theme.spacing(2)
    }
});

const mapStateToProps = (state: AppState) => ({
    allStreamsOrdered: getAllStreams(state).orderBy(x => x.order)
});

const mapDispatchToProps = {
    hideStream
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type SelectStreamsSettingsProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & {};

const SelectStreamsSettings: FunctionComponent<SelectStreamsSettingsProps> = (props) => {
    const { classes } = props;
    const { allStreamsOrdered } = props;
    const { hideStream } = props;

    const toggleStream = (streamId: number) => {
        hideStream(streamId, !allStreamsOrdered.get(streamId).isHidden);
    };

    return (
        <div className={classes.root}>
            {allStreamsOrdered && allStreamsOrdered.length > 0 ?
                <List>
                    {allStreamsOrdered.map(stream =>
                        <ListItem key={stream.id} dense button onClick={() => toggleStream(stream.id)}>
                            <ListItemIcon className={classes.listItemIcon}>
                                {mapStreamTypeToIcon.hasOwnProperty(stream.streamType) ? mapStreamTypeToIcon[stream.streamType] : mapStreamTypeToIcon['Default']}
                            </ListItemIcon>
                            <ListItemText
                                className={classes.listItemText}
                                primary={stream.title}
                                secondary={stream.url}
                            />
                            <ListItemSecondaryAction>
                                <Switch edge='end' checked={!stream.isHidden} onChange={(_, checked) => hideStream(stream.id, !checked)} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
                :
                <Box display='flex' justifyContent='center' alignItems='center'>
                    <Typography variant='subtitle1'>
                        No streams added
                    </Typography>
                </Box>
            }
        </div>
    );
}

export default connector(withStyles(styles)(SelectStreamsSettings));