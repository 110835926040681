import React, { FunctionComponent } from 'react';


type StopIFrameFlashProps = {
    onLoadPropertyName?: string
};

const StopIFrameFlash: FunctionComponent<StopIFrameFlashProps> = (props) => {
    const [isVisible, setVisibility] = React.useState(false);

    const onLoad = () => {
        setVisibility(true);
    };

    const visibilityStyle = {
        visibility: isVisible ? 'visible' : 'hidden'
    };

    return (
        <React.Fragment>
            {React.cloneElement(props.children as React.ReactElement<any>, {
                [props.onLoadPropertyName || 'onLoad']: onLoad, 
                style: visibilityStyle
            })}
        </React.Fragment>
    );
};

export default StopIFrameFlash;