import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import FormState from '../../../utils/functions/FormState';
import { getMultistream } from '../../selectors/playerSelectors';
import { editMultistreamGeneralInfo } from '../../actions/multistreamActions';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { AppState } from '../../../site/types/appState';

const mapStateToProps = (state: AppState) => ({
    multistream: getMultistream(state)
});

const mapDispatchToProps = {
    editMultistreamGeneralInfo
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type GeneralInformationSettingsProps = ConnectedProps<typeof connector> & {};

const GeneralInformationSettings: FunctionComponent<GeneralInformationSettingsProps> = (props) => {
    const { multistream } = props;
    const { editMultistreamGeneralInfo } = props;

    var formState = FormState.create({
        title: { value: multistream.title, required: true, maxLength: 255 },
        description: { value: multistream.description, maxLength: 255 },
        isPrivate: { value: multistream.isPrivate }
    });

    formState.onFormChange = () => {
        var formValues = formState.getValues();
        editMultistreamGeneralInfo(formValues.title, formValues.description, formValues.isPrivate);
    }

    const isReadOnly = !multistream.isOwner;

    return (
        <Container maxWidth='md'>
            <Box display='flex' flexDirection='column'>
                <TextField
                    id='title'
                    label='Title'
                    value={formState.field('title').value}
                    onChange={formState.field('title').onChange}
                    error={formState.field('title').error}
                    helperText={formState.field('title').helperText}
                    variant='outlined'
                    required
                    fullWidth
                    InputProps={{ readOnly: isReadOnly }}
                />
                <TextField
                    id='description'
                    label='Description'
                    value={formState.field('description').value}
                    onChange={formState.field('description').onChange}
                    error={formState.field('description').error}
                    helperText={formState.field('description').helperText}
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    InputProps={{ readOnly: isReadOnly }}
                />
                <FormControlLabel control={
                    <Checkbox 
                        checked={formState.field('isPrivate').checked} 
                        onChange={!isReadOnly ? formState.field('isPrivate').onChange : undefined} 
                        readOnly={isReadOnly}
                    />
                } label="Private multistream" />
            </Box>
        </Container>
    );
}

export default connector(GeneralInformationSettings);