import Vector2, { VectorUnit } from './Vector2';

export default class Size {
    private readonly _vector: Vector2;

    static fromVector(vector: Vector2): Size {
        return new Size(vector.x, vector.y, vector.unit);
    }

    constructor(width: number, height: number, unit: VectorUnit) {
        this._vector = new Vector2(width, height, unit);
    }

    public clone(): Size {
        return new Size(this.width, this.height, this.unit);
    }

    public get width(): number {
        return this._vector.x;
    }

    public get height(): number {
        return this._vector.y;
    }

    public convertUnitToPercent(): Size {
        if(this.unit === '%') return this;

        var newVector = this._vector.clone();
        newVector.convertUnitToPercent();
        return Size.fromVector(newVector);
    }

    public convertUnitToPixel(): Size {
        if(this.unit === 'px') return this;

        var newVector = this._vector.clone();
        newVector.convertUnitToPixel();
        return Size.fromVector(newVector);
    }

    private get unit(): VectorUnit {
        return this._vector.unit;
    }
}