import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setStreamDefaultMaskRectangle } from '../../../actions/streamActions';
import { setStreamSynchronizationSupport } from '../../../actions/streamSynchronizationActions';
import ReactPlayerStream from './ReactPlayerStream';

const mapDispatchToProps = {
    setStreamDefaultMaskRectangle,
    setStreamSynchronizationSupport
};

const connector = connect(null, mapDispatchToProps);

type YoutubeStreamProps = ConnectedProps<typeof connector> & {
    streamId: number;
    sourceKey: string;
};

const YoutubeStream: FunctionComponent<YoutubeStreamProps> = (props) => {
    const { streamId, sourceKey } = props;
    const { setStreamDefaultMaskRectangle, setStreamSynchronizationSupport } = props;

    React.useEffect(() => {
        if(streamId !== 0) {
            setStreamDefaultMaskRectangle(streamId, { height: 'calc(100% - 50px)' });
            setStreamSynchronizationSupport(streamId, true);
        }
    }, [streamId, setStreamDefaultMaskRectangle, setStreamSynchronizationSupport]);

    const videoUrl = 'https://www.youtube.com/embed/' + sourceKey;

    return (
        <ReactPlayerStream streamId={streamId} url={videoUrl} />
    );
}

export default connector(YoutubeStream);