import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { createGameUrl } from '../../utils/playerUrlCreators';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link as RouterLink } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

const cardWidth = 192;

const styles = (theme: Theme) => createStyles({
    card: {
        minWidth: cardWidth,
        maxWidth: cardWidth
    },
    thumbnail: {
        height: cardWidth * 4 / 3
    },
    content: {
        paddingTop: 8,
        paddingBottom: 8
    }
});

type GameCardProps = WithStyles<typeof styles> & {
    id: string;
    name: string;
    thumbnailUrl: string;
};

const GameCard: FunctionComponent<GameCardProps> = (props) => {
    const { classes } = props;
    const { id, name, thumbnailUrl } = props;
    
    const url = createGameUrl(id, name);

    return (
        <Box m='15px'>
            <Link component={RouterLink} to={url} color='inherit' underline='none'>
                <Card className={classes.card} raised title={name}>
                    <CardActionArea>
                        <CardMedia className={classes.thumbnail} image={thumbnailUrl} />
                        <CardContent className={classes.content}>
                            <Typography variant="body1" align='center' noWrap >
                                <b>{name}</b>
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Link>
        </Box>
    );
}

export default withStyles(styles)(GameCard);