import { AppDispatch } from '../../site/types/actionTypes';
import { action } from '../../utils/functions/reduxUtils';
import { updateComponentLayers } from './streamsContainerActions';
import { getVisibleComponents } from '../selectors/playerSelectors';
import { calculateComponentSnapToComponents, SnapMode, calculateSnapPaneForComponent, calculateSnapPaneForPageEdge } from './helpers/streamSnapHelper';
import Position from '../../utils/functions/Position';
import Size from '../../utils/functions/Size';
import { AppState } from '../../site/types/appState';
import { detectedMultistreamChanges } from './playerActions';
import { ComponentPayload } from '../types/payloadTypes';
import { ComponentState } from '../types/componentState';
import { SnapPane } from '../types/snapPane';
import { LayoutState } from '../types/layoutState';
import { DenormalizedLayout } from '../types/layoutTemplate';

export const types = {
    UPDATE_COMPONENT_POSITION: 'component/UPDATE_COMPONENT_POSITION',
    UPDATE_COMPONENT_SIZE: 'component/UPDATE_COMPONENT_SIZE',
    SET_COMPONENT_FRAME_ACTIVE: 'component/SET_COMPONENT_FRAME_ACTIVE',
    SWAP_COMPONENT_WITH_LARGEST: 'component/SWAP_COMPONENT_WITH_LARGEST',
    UPDATE_COMPONENT_STATE: 'component/UPDATE_COMPONENT_STATE',
    UPDATE_SNAP_PANE: 'component/UPDATE_SNAP_PANE',
    MERGE_COMPONENTS: 'component/MERGE_COMPONENTS',
    UPDATE_LAYOUT_STATE: 'layout/UPDATE_LAYOUT_STATE'
};

export const updateComponentPosition = (componentId: number, newPosition: Position) => {
    return (dispatch: AppDispatch) => {
        newPosition = newPosition.convertUnitToPercent();
        dispatch(action<ComponentPayload<Position>>(types.UPDATE_COMPONENT_POSITION, { componentId, data: newPosition }));
        dispatch(detectedMultistreamChanges());
        dispatch(detectedLayoutEdit());
    };
};

export const updateComponentSize = (componentId: number, newSize: Size) => {
    return (dispatch: AppDispatch) => {
        newSize = newSize.convertUnitToPercent();
        dispatch(action<ComponentPayload<Size>>(types.UPDATE_COMPONENT_SIZE, { componentId, data: newSize }));
        dispatch(savePreSnapComponentSize(componentId, null));
        dispatch(updateComponentLayers());
        dispatch(detectedMultistreamChanges());
        dispatch(detectedLayoutEdit());
    };
};

export const tryToSnapComponent = (componentId: number, snapMode: SnapMode, pointerPosition?: Position) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const components = getVisibleComponents(getState());
        const component = components.get(componentId);
        const otherComponents = components.filter(x => x.id !== componentId);

        if (pointerPosition != null && snapMode === 'drag') {
            const targetComponent = calculateSnapPaneForComponent(pointerPosition, otherComponents);
            if (targetComponent != null) {
                dispatch(mergeComponents(componentId, targetComponent.id));
                return;
            }

            const snapPane = calculateSnapPaneForPageEdge(pointerPosition);
            if (snapPane != null) {
                const preSnapSize = component.size;
                dispatch(updateComponentPosition(componentId, snapPane.position));
                dispatch(updateComponentSize(componentId, snapPane.size));
                dispatch(savePreSnapComponentSize(componentId, preSnapSize));
                return;
            }
        }

        const snapResult = calculateComponentSnapToComponents(component.position, component.size, otherComponents, snapMode);
        if (snapResult.newPosition != null) {
            dispatch(updateComponentPosition(componentId, snapResult.newPosition));
        }
        if (snapResult.newSize != null) {
            dispatch(updateComponentSize(componentId, snapResult.newSize));
        }
    };
};

export const setComponentFrameActive = (componentId: number, isFrameActive: boolean) =>
    action<ComponentPayload<boolean>>(types.SET_COMPONENT_FRAME_ACTIVE, { componentId, data: isFrameActive });

export const setComponentShowSettings = (componentId: number, showSettings: boolean) =>
    action<ComponentPayload<ComponentState>>(types.UPDATE_COMPONENT_STATE, { componentId, data: { showSettings } });

export const swapComponentWithLargest = (componentId: number) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<number>(types.SWAP_COMPONENT_WITH_LARGEST, componentId));
        dispatch(updateComponentLayers());
        dispatch(detectedMultistreamChanges());
    };
};

export const setComponentFullscreen = (componentId: number) => {
    return (dispatch: AppDispatch) => {
        const newPosition = new Position(0, 0, 'px');
        const newSize = new Size(100, 100, '%').convertUnitToPixel();

        dispatch(updateComponentPosition(componentId, newPosition));
        dispatch(updateComponentSize(componentId, newSize));
    };
};

export const tryToShowSnapPane = (componentId?: number, pointerPosition?: Position) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        if (componentId == null || pointerPosition == null) {
            dispatch(action<SnapPane>(types.UPDATE_SNAP_PANE, null));
            return;
        }

        const components = getVisibleComponents(getState());
        const otherComponents = components.filter(x => x.id !== componentId);
        const targetComponent = calculateSnapPaneForComponent(pointerPosition, otherComponents);

        if (targetComponent != null) {
            dispatch(action<SnapPane>(types.UPDATE_SNAP_PANE, { position: targetComponent.position, size: targetComponent.size, pointerPosition }));
            return;
        }

        const snapPane = calculateSnapPaneForPageEdge(pointerPosition);
        dispatch(action<SnapPane>(types.UPDATE_SNAP_PANE, snapPane));
    };
};

export const mergeComponents = (componentId: number, targetMultiComponentId: number) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<ComponentPayload<number>>(types.MERGE_COMPONENTS, { componentId, data: targetMultiComponentId }));
        dispatch(detectedMultistreamChanges());
    };
}

export const savePreSnapComponentSize = (componentId: number, previousSize: Size) => 
    action<ComponentPayload<ComponentState>>(types.UPDATE_COMPONENT_STATE, { componentId, data: { preSnapSize: previousSize } });

export const detectedLayoutEdit = () => action<LayoutState>(types.UPDATE_LAYOUT_STATE, { editedLayout: true });
export const setPreviousLayout = (layout: DenormalizedLayout) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<LayoutState>(types.UPDATE_LAYOUT_STATE, { previousLayout: layout }));
        dispatch(action<LayoutState>(types.UPDATE_LAYOUT_STATE, { editedLayout: false }));
    };
}
