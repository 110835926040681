import React, { FunctionComponent } from 'react';

type DocumentScrollbarsProps =  {
    hide?: boolean;
    forceYScroll?: boolean;
    forceXScroll?: boolean;
};

const DocumentScrollbars: FunctionComponent<DocumentScrollbarsProps> = (props) => {
    const { hide, forceYScroll, forceXScroll } = props;

    React.useEffect(() => {
        if(hide) {
            hideScrollbars();
        }
        else {
            showScrollbars();
        }
    });

    React.useEffect(() => {
        return () => resetScrollbars();
    }, []);

    const showScrollbars = () => {
        document.documentElement.style.overflow = 'auto';

        if(forceYScroll) {
            document.documentElement.style.overflowY = 'scroll';
        }
        
        if(forceXScroll) {
            document.documentElement.style.overflowX = 'scroll';
        }
    };

    const hideScrollbars = () => {
        document.documentElement.style.overflow = 'hidden';
    };

    const resetScrollbars = () => {
        document.documentElement.style.overflow = 'initial';
        document.documentElement.style.overflowY = 'initial';
        document.documentElement.style.overflowX = 'initial';
    };

    return null;
}

export default DocumentScrollbars;