import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Component } from '../../types/multistream';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import MultistreamLayoutMockup from './MultistreamLayoutMockup';
import NormalizedEntities from '../../../utils/functions/NormalizedEntities';

const styles = (theme: Theme) => createStyles({
    card: {
        minWidth: 200,
        maxWidth: 200
    },
    thumbnail: {
        height: 100
    },
    content: {
        paddingTop: 4,
        paddingBottom: 4
    }
});

type MultistreamLayoutMockupCardProps = WithStyles<typeof styles> & {
    title: string,
    components: Array<Component> | NormalizedEntities<Component>,
    onClick?: () => void
};

const MultistreamLayoutMockupCard: FunctionComponent<MultistreamLayoutMockupCardProps> = (props) => {
    const { classes } = props;
    const { title, components } = props;
    const { onClick } = props;

    return (
        <Box m={2}>
            <Card className={classes.card} raised title={title}>
                <CardActionArea onClick={onClick}>
                    <Box className={classes.thumbnail}>
                        <MultistreamLayoutMockup components={components} />
                    </Box>
                    <CardContent className={classes.content}>
                        <Typography variant="body1" align='center' noWrap >
                            {title}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    );
}

export default withStyles(styles)(MultistreamLayoutMockupCard);