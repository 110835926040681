import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../site/types/appState';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fade from '@material-ui/core/Fade';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { getComponent } from '../../selectors/playerSelectors';
import { Stream } from '../../types/multistream';
import { toggleStreamInMultiComponent } from '../../actions/streamActions';


const styles = (theme: Theme) => createStyles({
    tabsPanel: {
        position: 'fixed',
        top: 0,
        left: 0,
        maxWidth: `calc(100% - ${theme.spacing(10)}px)`,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 3
    },
    styledTabsRoot: {
        minHeight: theme.spacing(4.5)
    },
    styledTabRoot: {
        textTransform: 'none',
        color: '#fff',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: theme.spacing(8),
        maxWidth: theme.spacing(24),
        minHeight: theme.spacing(4.5),
        '&:hover': {
            opacity: 0.9,
        },
        '&:focus': {
            opacity: 1,
        },
    },
    tabLabel: {
        width: '100%'
    }
});

type OwnProps = {
    streams: Array<Stream>;
    currentStreamId: number;
    componentId: number;
};

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
    component: getComponent(state, ownProps.componentId)
});

const mapDispatchToProps = {
    toggleStreamInMultiComponent
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type MultiComponentTabsProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & OwnProps;

const MultiComponentTabs: FunctionComponent<MultiComponentTabsProps> = (props) => {
    const { classes } = props;
    const { streams, currentStreamId, component } = props;
    const { toggleStreamInMultiComponent } = props;

    const [isMouseOverButton, setMouseOverButton] = React.useState(false);

    const isFrameActive = component.state.isFrameActive === true;
    const isHidden = component.state.showSettings !== true;

    const changeCurrentStream = (_: any, selectedStreamId: number) => {
        toggleStreamInMultiComponent(selectedStreamId);
    };

    const stopEventPropagation = (event: React.SyntheticEvent) => {
        event.stopPropagation();
    };

    return (
        <Box onMouseDown={stopEventPropagation} onMouseEnter={() => setMouseOverButton(true)} onMouseLeave={() => setMouseOverButton(false)}>
            <Fade in={!isFrameActive && (!isHidden || isMouseOverButton)} timeout={300}>
                <Box className={classes.tabsPanel}>
                    <Tabs
                        value={currentStreamId}
                        onChange={changeCurrentStream}
                        variant="scrollable"
                        scrollButtons="auto"
                        classes={{ root: classes.styledTabsRoot }}
                    >
                        {streams.map(stream =>
                            <Tab key={stream.id} value={stream.id} classes={{ root: classes.styledTabRoot }} wrapped label={
                                <Tooltip title={stream.title || stream.url}>
                                    <Box className={classes.tabLabel}>
                                        <Typography variant="body2" noWrap>
                                            {stream.title || stream.url}
                                        </Typography>
                                    </Box>
                                </Tooltip>
                            } />
                        )}
                    </Tabs>
                </Box>
            </Fade>
        </Box>
    );
};

export default connector(withStyles(styles)(MultiComponentTabs));