import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import { getCardsData } from '../../selectors/siteSelectors';
import { fetchCards } from '../../actions/cardsActions';
import VisibilitySensor from 'react-visibility-sensor';
import { AppState } from '../../types/appState';
import { useAuth0 } from '@auth0/auth0-react';
import { Card } from '../../types/cardsData';
import { Typography } from '@material-ui/core';

type OwnProps = {
    id: string;
    url?: string;
    render: (card: Card) => JSX.Element;
    maxItems?: number;
};

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
    cardsData: getCardsData(state, ownProps.id)
});

const mapDispatchToProps = {
    fetchCards
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type CardsDeckProps = ConnectedProps<typeof connector> & OwnProps

const CardsDeck: FunctionComponent<CardsDeckProps> = (props) => {
    const { id, url, render, maxItems } = props;
    const { cardsData } = props;
    const { fetchCards } = props;
    const authContext = useAuth0();
    
    React.useEffect(() => {
        if(!cardsData && url) {
            fetchCards(authContext, id, url);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchCards, id, url]);

    if(!cardsData){
        return null;
    }

    const bottomVisibilitySensorTrigger = (isVisible: boolean) => {
        if(isVisible) {
            fetchCards(authContext, id, url, cardsData.forwardCursor);
        }
    };

    var cardComponents = cardsData.cards && cardsData.cards.length > 0 && cardsData.cards.map(card => render(card));

    if(cardComponents && cardComponents.length > 0 && cardsData.state === 'loaded' && !maxItems) {
        var lastCardComponent = cardComponents[cardComponents.length - 1];
        const visibilitySensorCardComponent = <VisibilitySensor key={'VS-' + lastCardComponent.key} onChange={bottomVisibilitySensorTrigger} 
            partialVisibility>
            {lastCardComponent}
        </VisibilitySensor>;
        cardComponents[cardComponents.length - 1] = visibilitySensorCardComponent;
    }
    
    if(maxItems && cardComponents && cardComponents.length > maxItems) {
        cardComponents.splice(maxItems - cardComponents.length);
    }

    return (
        <Box my={2}>
            <Box display='flex' flexWrap="wrap" justifyContent='center'>
                {cardComponents}
            </Box>

            {cardsData.state === 'loading' &&
                <Container>
                    <Box display='flex' justifyContent='center' my={6}>
                        <CircularProgress />
                    </Box>
                </Container>
            }

            {!cardComponents && cardsData.state === 'loadedAll' &&
                <Container>
                    <Box display='flex' justifyContent='center' my={6} py={0.5}>
                        <Typography variant='h5'>Sorry, no results were found.</Typography>
                    </Box>
                </Container>
            }

            {cardsData.state === 'error' &&
                <Container>
                    <Box display='flex' justifyContent='center' my={6}>
                        <Button variant="contained" color="secondary" startIcon={<RefreshIcon/>} onClick={() => fetchCards(authContext, id, url)}>Reload</Button>
                    </Box>
                </Container>
            }
        </Box> 
    );
}


export default connector(CardsDeck);