export type EntitiesObjectType<T> = { [id: number]: T };

export default class NormalizedEntities<T> {
    private readonly _entityIds: number[];
    private readonly _entitiesObject: EntitiesObjectType<T>;

    constructor(entityIds: number[], entitiesObject: EntitiesObjectType<T>) {
        this._entityIds = entityIds != null ? entityIds : [];
        this._entitiesObject = entitiesObject != null ? entitiesObject : {};
    }

    get ids(): number[] {
        return this._entityIds;
    }

    get obj(): EntitiesObjectType<T> {
        return this._entitiesObject;
    }

    get length(): number {
        return this._entityIds.length;
    }

    get(entityId: number): T {
        return this._entitiesObject[entityId];
    }

    at(index: number): T {
        return this._entitiesObject[this._entityIds[index]];
    }

    forEach(callbackFn: (currentValue: T, index?: number, entityIds?: number[]) => void) {
        this._entityIds.forEach((entityId, index, entityIds) => callbackFn(this.get(entityId), index, entityIds));
    }

    filter(predicate: (element: T, index?: number, entityIds?: number[]) => boolean): NormalizedEntities<T> {
        const filteredIds = this._entityIds.filter((entityId, index, entityIds) => predicate(this.get(entityId), index, entityIds));
        const newEntities = new NormalizedEntities<T>(filteredIds, this._entitiesObject);
        return newEntities;
    }

    map(callbackFn: (currentValue: T, index?: number, entityIds?: number[]) => any): any[] {
        const mappedStreams = this._entityIds.map((entityId, index, entityIds) => callbackFn(this.get(entityId), index, entityIds));
        return mappedStreams;
    }

    find(predicate: (element: T, index?: number, entityIds?: number[]) => boolean): T {
        const foundEntityId = this._entityIds.find((entityId, index, entityIds) => predicate(this.get(entityId), index, entityIds));

        if (foundEntityId == null)
            return null;

        return this.get(foundEntityId);
    }

    all(predicate: (element: T, index?: number, entityIds?: number[]) => boolean): boolean {
        return this.find((element, index, entityIds) => !predicate(element, index, entityIds)) == null;
    }

    any(predicate: (element: T, index?: number, entityIds?: number[]) => boolean): boolean {
        return this.find(predicate) != null;
    }

    orderBy<U>(keySelector: (element: T) => U): NormalizedEntities<T> {
        const newEntityIds = this._entityIds.slice();
        newEntityIds.sort((entityIdA, entityIdB) => {
            const comparedValueA = keySelector(this.get(entityIdA));
            const comparedValueB = keySelector(this.get(entityIdB));

            return comparedValueA < comparedValueB ? -1
                : comparedValueA > comparedValueB ? 1
                    : 0;
        });

        const orderedEntites = new NormalizedEntities<T>(newEntityIds, this._entitiesObject);
        return orderedEntites;
    }

    orderByDescending<U>(keySelector: (element: T) => U): NormalizedEntities<T> {
        const newEntityIds = this._entityIds.slice();
        newEntityIds.sort((entityIdA, entityIdB) => {
            const comparedValueA = keySelector(this.get(entityIdA));
            const comparedValueB = keySelector(this.get(entityIdB));

            return comparedValueA < comparedValueB ? 1
                : comparedValueA > comparedValueB ? -1
                    : 0;
        });

        const orderedEntites = new NormalizedEntities<T>(newEntityIds, this._entitiesObject);
        return orderedEntites;
    }
}