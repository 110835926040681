import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Component } from '../../types/multistream';
import Box from '@material-ui/core/Box';
import NormalizedEntities from '../../../utils/functions/NormalizedEntities';

const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: 'black',
        border: '1px solid #303030',
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    componentMockup: {
        border: '1px dashed #303030',
        position: 'absolute'
    },
    color1: {
        backgroundColor: theme.palette.primary.dark
    },
    color2: {
        backgroundColor: theme.palette.primary.main
    },
    color3: {
        backgroundColor: theme.palette.primary.light
    }
});

type MultistreamLayoutMockupProps = WithStyles<typeof styles> & {
    components: Array<Component> | NormalizedEntities<Component>;
};

const MultistreamLayoutMockup: FunctionComponent<MultistreamLayoutMockupProps> = (props) => {
    const { classes } = props;
    const { components } = props;

    const calculateSizeProperty = (sizeProperty: number, positionProperty: number): number => {
        if(positionProperty >= 0) {
            if(positionProperty + sizeProperty > 100) {
                return 100 - positionProperty;
            }
            else {
                return Math.min(sizeProperty, 100);
            }
        }
        else {
            return Math.min(sizeProperty + positionProperty, 100);
        }
    }

    const rectangles = components.map((x: Component) => {
        const positionPercent = x.position.convertUnitToPercent();
        const sizePercent = x.size.convertUnitToPercent();
        const colorNumber = x.order % 3 + 1;

        return {
            key: x.order,
            left: Math.max(positionPercent.left, 0) + '%',
            top: Math.max(positionPercent.top, 0) + '%',
            width: calculateSizeProperty(sizePercent.width, positionPercent.left) + '%',
            height: calculateSizeProperty(sizePercent.height, positionPercent.top) + '%',
            colorClass: colorNumber === 1 ? classes.color1 : colorNumber === 2 ? classes.color2 : classes.color3,
            layer: x.state.layer
        }
    });

    rectangles.sort((a, b) => (a.layer - b.layer));

    return (
        <Box className={classes.root}>
            {rectangles.map(x => 
                <Box key={x.key} left={x.left} top={x.top} width={x.width} height={x.height} className={`${classes.componentMockup} ${x.colorClass}`}/>
                )}
        </Box>
    );
}

export default withStyles(styles)(MultistreamLayoutMockup);