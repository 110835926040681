import { action } from '../../utils/functions/reduxUtils';
import { AppDispatch } from '../../site/types/actionTypes';
import { notify } from '../../site/actions/siteActions';
import { Stream } from '../types/multistream';
import { getPlayer, getStream } from '../selectors/playerSelectors';
import { AppState } from '../../site/types/appState';
import { requestNextMultiVideoStreamsIfNeeded, assignNextMultiVideoStream } from './streamActions';
import { detectedMultistreamChanges } from './playerActions';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { StreamPayload } from '../types/payloadTypes';
import { DenormalizedLayout } from '../types/layoutTemplate';

export const types = {
    UPDATE_COMPONENT_LAYERS: 'streamsContainer/UPDATE_COMPONENT_LAYERS',
    ADD_STREAM: 'streamsContainer/ADD_STREAM',
    REMOVE_STREAM: 'streamsContainer/REMOVE_STREAM',
    DUPLICATE_STREAM: 'streamsContainer/DUPLICATE_STREAM',
    EDIT_STREAM: 'streamsContainer/EDIT_STREAM',
    DETACH_STREAM_FROM_MULTICOMPONENT: 'streamsContainer/DETACH_STREAM_FROM_MULTICOMPONENT',
    CHANGE_MULTISTREAM_LAYOUT: 'streamsContainer/CHANGE_MULTISTREAM_LAYOUT'
};

export const updateComponentLayers = () => action(types.UPDATE_COMPONENT_LAYERS);

export const addNewStream = (authContext: Auth0ContextInterface, userTitle: string) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const streamDetailsModalData = getPlayer(getState()).streamDetailsModalData;
        const newStream = { ...streamDetailsModalData.stream, title: userTitle};
        dispatch(action<Stream>(types.ADD_STREAM, newStream));

        if(newStream.streamType === 'MultiVideo') {
            dispatch(assignNextMultiVideoStream(authContext, newStream.id));
            dispatch(requestNextMultiVideoStreamsIfNeeded(authContext, newStream.sourceType, newStream.sourceKey));
        }

        dispatch(updateComponentLayers());
        dispatch(detectedMultistreamChanges());
        dispatch(notify('Added new stream', 'success'));
    }
};

export const editStream = (authContext: Auth0ContextInterface, userTitle: string) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const streamDetailsModalData = getPlayer(getState()).streamDetailsModalData;
        const newStream = { ...streamDetailsModalData.stream, title: userTitle };
        dispatch(action<StreamPayload<Stream>>(types.EDIT_STREAM, { streamId: streamDetailsModalData.editStreamId, data: newStream }));

        if(newStream.streamType === 'MultiVideo') {
            dispatch(assignNextMultiVideoStream(authContext, newStream.id));
            dispatch(requestNextMultiVideoStreamsIfNeeded(authContext, newStream.sourceType, newStream.sourceKey));
        }

        dispatch(detectedMultistreamChanges());
        dispatch(notify('Edited stream', 'success'));
    };
};

export const removeStream = (streamId: number) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<number>(types.REMOVE_STREAM, streamId));
        dispatch(updateComponentLayers());
        dispatch(detectedMultistreamChanges());
    };
};

export const duplicateStream = (streamId: number) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<number>(types.DUPLICATE_STREAM, streamId));
        dispatch(updateComponentLayers());
        dispatch(detectedMultistreamChanges());
    };
};

export const addNewChatStream = (streamId: number) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const parentStream = getStream(getState(), streamId);
        const newStreamChat: Stream = { 
            ...parentStream,
            title: parentStream.title + ' chat',
            sourceType: 'chat',
            state: {}
        };

        dispatch(action<Stream>(types.ADD_STREAM, newStreamChat));
        dispatch(updateComponentLayers());
        dispatch(detectedMultistreamChanges());
    };
};

export const detachStreamFromMultiComponent = (streamId: number) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<number>(types.DETACH_STREAM_FROM_MULTICOMPONENT, streamId));
        dispatch(detectedMultistreamChanges());
    };
}

export const changeMultistreamLayout = (layout: DenormalizedLayout) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<DenormalizedLayout>(types.CHANGE_MULTISTREAM_LAYOUT, layout));
        dispatch(updateComponentLayers());
    };
}