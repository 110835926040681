import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import MultistreamPlayer from '../components/MultistreamPlayer';
import { fetchMultistream } from '../actions/multistreamActions';
import { requestNextMultiVideoStreamsIfNeeded } from '../actions/streamActions';
import { useAuth0 } from '@auth0/auth0-react';

const mapDispatchToProps = { 
    fetchMultistream,
    requestNextMultiVideoStreamsIfNeeded
};

const connector = connect(null, mapDispatchToProps);

type GameProps = ConnectedProps<typeof connector> & RouteComponentProps<{
    id: string
}>;

const Game: FunctionComponent<GameProps> = (props) => {
    const { fetchMultistream, requestNextMultiVideoStreamsIfNeeded } = props;
    const authContext = useAuth0();
    const gameId = props.match.params.id;

    React.useEffect(() => {
        const apiUrl = '/api/games/' + gameId;
        fetchMultistream(authContext, apiUrl);
        requestNextMultiVideoStreamsIfNeeded(authContext, 'game', gameId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameId]);

    return (
        <MultistreamPlayer />
    );
}

export default connector(Game);