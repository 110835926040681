import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import StreamFrame from './StreamFrame';
import StreamMask from './StreamMask';
import StreamPlayerSelector from './StreamPlayerSelector';
import StreamSettings from './StreamSettings';
import { getStream } from '../../selectors/playerSelectors';
import { setComponentFrameActive } from '../../actions/componentActions';
import { AppState } from '../../../site/types/appState';
import MultiComponentTabs from './MultiComponentTabs';

type OwnProps = {
    streamIds: Array<number>;
    componentId: number;
};

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
    streams: ownProps.streamIds.map(streamId => getStream(state, streamId))
});

const mapDispatchToProps = {
    setComponentFrameActive
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type MultiComponentProps = ConnectedProps<typeof connector> & OwnProps;

const MultiComponent: FunctionComponent<MultiComponentProps> = (props) => {
    const { streams, componentId } = props;
    const currentStream = streams.find(x => !x.isHidden);

    return (
        <StreamFrame 
                streamId={currentStream.id}
                componentId={componentId}
                isInteractive >
            <StreamPlayerSelector stream={currentStream} />
            <StreamMask streamId={currentStream.id} componentId={componentId} />
            <StreamSettings streamId={currentStream.id} componentId={componentId}  />
            <MultiComponentTabs streams={streams} currentStreamId={currentStream.id} componentId={componentId} />
        </StreamFrame>
    );
};

export default connector(MultiComponent);