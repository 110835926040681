import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import StreamSettingsBase, { StreamSettingsOption } from './StreamSettingsBase';
import { hideStream, assignNextMultiVideoStream } from '../../actions/streamActions';
import { toggleStreamSynchronization } from '../../actions/streamSynchronizationActions';
import { removeStream, duplicateStream, addNewChatStream, detachStreamFromMultiComponent } from '../../actions/streamsContainerActions';
import { openEditStreamDetailModal } from '../../actions/playerActions';
import { swapComponentWithLargest, setComponentFullscreen } from '../../actions/componentActions';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import EditIcon from '@material-ui/icons/Edit';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloseIcon from '@material-ui/icons/Close';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import ChatIcon from '@material-ui/icons/Chat';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
import SyncIcon from '@material-ui/icons/Sync';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import { AppState } from '../../../site/types/appState';
import { getStream, getComponent, getStreamWithDifferentSourceType, getMultistreamFlags, getAllStreams, getVisibleComponents } from '../../selectors/playerSelectors';
import { useAuth0 } from '@auth0/auth0-react';

type OwnProps = {
    streamId: number;
    componentId: number;
};

const styles = (theme: Theme) => createStyles({
    buttonPanel: {
        position: 'fixed',
        top: theme.spacing(1),
        right: theme.spacing(1)
    },
    optionTitle: {
        marginLeft: theme.spacing(1)
    }
});

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
    stream: getStream(state, ownProps.streamId),
    component: getComponent(state, ownProps.componentId),
    visibleComponents: getVisibleComponents(state),
    chatStream: getStreamWithDifferentSourceType(state, ownProps.streamId, 'chat'),
    embededStreams: getMultistreamFlags(state).embededStreams === true,
    isMultiComponent: getAllStreams(state).filter(x => x.order === getComponent(state, ownProps.componentId).order).length > 1
 });

const mapDispatchToProps = {
    hideStream,
    removeStream,
    assignNextMultiVideoStream,
    toggleStreamSynchronization,
    duplicateStream,
    openEditStreamDetailModal,
    swapComponentWithLargest,
    setComponentFullscreen,
    addNewChatStream,
    detachStreamFromMultiComponent
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StreamSettingsProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & OwnProps;

const StreamSettings: FunctionComponent<StreamSettingsProps> = (props) => {
    const { streamId, stream, componentId, component, chatStream } = props;
    const { visibleComponents } = props;
    const { embededStreams, isMultiComponent } = props;
    const { hideStream, assignNextMultiVideoStream, toggleStreamSynchronization } = props;
    const { removeStream, duplicateStream, addNewChatStream, detachStreamFromMultiComponent } = props;
    const { openEditStreamDetailModal } = props;
    const { swapComponentWithLargest, setComponentFullscreen } = props;

    const authContext = useAuth0();
    
    const componentArea = component.size.width * component.size.height;
    const isLargest = visibleComponents.find(x => x.size.width * x.size.height > componentArea) == null;
    const isFullscreen = Math.abs(component.position.x) <= 1 && Math.abs(component.position.y) <= 1 && 
                            Math.abs(component.size.width - 100) <= 1 && Math.abs(component.size.height - 100) <= 1;

    const openStreamInNewTab = () => {
        const streamUrl = stream.streamType === 'MultiVideo' && stream.state?.multiVideoStream?.url ? stream.state.multiVideoStream.url : stream.url;
        window.open(streamUrl, "_blank");
    };

    const streamSettingsOptions: Array<StreamSettingsOption> = [
        { 
            title: 'Next', icon: <SkipNextIcon />, 
            action: (streamId) => assignNextMultiVideoStream(authContext, streamId), 
            hidden: stream.streamType !== 'MultiVideo'
        },
        { 
            title: 'Swap with largest', icon: <SwapVertIcon />, 
            action: (_, componentId) => swapComponentWithLargest(componentId),
            hidden: isLargest
        },
        { 
            title: 'Fullscreen', icon: <FullscreenIcon />, 
            action: (_, componentId) => setComponentFullscreen(componentId),
            hidden: !isLargest || isFullscreen || embededStreams
        },
        {
            title: !chatStream ? 'Add chat' : chatStream.isHidden ? 'Show chat' : 'Remove chat',
            icon: <ChatIcon />,
            action: (streamId) => !chatStream ? addNewChatStream(streamId) : chatStream.isHidden ? hideStream(chatStream.id, false) : removeStream(chatStream.id),
            hidden: stream.streamType !== 'Twitch' || stream.sourceType !== 'channel' || embededStreams
        },
        {
            title: 'Detach from tabs',
            icon: <FlipToFrontIcon />,
            action: (streamId) => detachStreamFromMultiComponent(streamId),
            hidden: !isMultiComponent
        },
        { 
            title: 'Enable sync', 
            icon: <SyncIcon />, 
            action: (streamId) => toggleStreamSynchronization(streamId, true, new Date().getTime()), 
            hidden: !stream.state.synchronizationSupport || stream.synchronization != null 
        },
        { 
            title: 'Disable sync', 
            icon: <SyncDisabledIcon />, 
            action: (streamId) => toggleStreamSynchronization(streamId, false, new Date().getTime()), 
            hidden: !stream.state.synchronizationSupport || stream.synchronization == null 
        },
        { title: 'Hide', icon: <CloseIcon />, action: (streamId) => hideStream(streamId), hidden: embededStreams },
        { title: 'Edit', icon: <EditIcon />, action: openEditStreamDetailModal, hidden: embededStreams },
        { title: 'Duplicate', icon: <AddToPhotosIcon />, action: duplicateStream, hidden: embededStreams },
        { title: 'Remove', icon: <DeleteIcon />, action: removeStream, hidden: embededStreams },
        { title: 'Open in new tab', icon: <OpenInNewIcon />, action: openStreamInNewTab }
    ];

    return (
        <StreamSettingsBase streamId={streamId} componentId={componentId} streamSettingsOptions={streamSettingsOptions} />
    );
};

export default connector(withStyles(styles)(StreamSettings));