import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { AppState } from '../../../site/types/appState';
import { getStream, getComponent, getPlayerFlags, getMultistreamFlags } from '../../selectors/playerSelectors';

const styles = (theme: Theme) => createStyles({
    mask: {
        position: 'absolute',
        display: 'block',
        background: 'transparent',
        opacity: '0'
    },
    debugMask: {
        background: 'green',
        opacity: '0.6'
    }
});

type MaskState = 'default' | 'full' | 'hidden';

type OwnProps = {
    streamId: number;
    componentId: number;
    isDebug?: boolean;
};

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
    stream: getStream(state, ownProps.streamId),
    component: getComponent(state, ownProps.componentId),
    fullStreamMasks: getPlayerFlags(state).fullStreamMasks === true,
    embededStreams: getMultistreamFlags(state).embededStreams === true
});

const connector = connect(mapStateToProps);

type StreamMaskProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & OwnProps;

const StreamMask: FunctionComponent<StreamMaskProps> = (props) => {
    const { classes } = props;
    const { stream, component, fullStreamMasks, embededStreams } = props;

    const maskState = component.state.isFrameActive ? 'hidden' : fullStreamMasks ? 'full' : 'default';
    const defaultMaskRectangle = stream.state.defaultMaskRectangle;

    if(maskState === 'hidden' || embededStreams) {
        return null;
    }

    var maskRectangle = {
        left: '0',
        top: '0',
        width: '100%',
        height: '100%'
    };

    if(maskState !== 'full' && defaultMaskRectangle) {
        maskRectangle.left = defaultMaskRectangle.x || maskRectangle.left;
        maskRectangle.top = defaultMaskRectangle.y || maskRectangle.top;
        maskRectangle.width = defaultMaskRectangle.width || maskRectangle.width;
        maskRectangle.height = defaultMaskRectangle.height || maskRectangle.height;
    }

    const debugMaskClass = props.isDebug ? classes.debugMask : '';

    return (
        <div className={`${classes.mask} ${debugMaskClass}`} style={maskRectangle} ></div>
    );
}

export default connector(withStyles(styles)(StreamMask));