import produce from 'immer';

interface ActionType {
    type: string;
    payload?: any;
}

type ActionHandlersType<T> = { [actionType: string]: (draft: T, payload?: any) => void };


/**
 * Example usage:
 * const testReducer = createReducer<StateType>({
 *     ['actionType']: (draft, payload) => {
 *         draft.property = payload.data;
 *     }
 *  }, initState);
 */
export const createReducer = <T extends unknown>(actionHandlers: ActionHandlersType<T> = {}) => {
    return (state: T, action: ActionType) => produce(state, draft => {
        for(var actionType in actionHandlers) {
            if(action.type === actionType && actionHandlers.hasOwnProperty(actionType)) {
                return actionHandlers[actionType](draft as T, action.payload);
            }
        }
    });
};


/**
 * Example usage
 * dispatch(action<PayloadType>(types.TYPE, {data}));
 * const myAction = (param1, param2) => action<PayloadType>(types.TYPE, { param1, param2 });
 */
export const action = <T extends {}>(actionType: string, payload: T = null) => ({
    type: actionType,
    payload: payload
});