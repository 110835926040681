import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import { AppState } from '../../../site/types/appState';
import { getPlayer } from '../../selectors/playerSelectors';
import { processNewStream, clearProcessingNewStream, reportProcessedStream, openStreamDetailModal } from '../../actions/playerActions';
import { addNewStream, editStream } from '../../actions/streamsContainerActions';
import FormState from '../../../utils/functions/FormState';
import StreamPlayerSelector from '../stream/StreamPlayerSelector';
import Paper from '@material-ui/core/Paper';
import { useAuth0 } from '@auth0/auth0-react';

const styles = (theme: Theme) => createStyles({
    streamContainer: {
        width: 350
    },
    streamMedia: {
        height: 240
    }
});

const mapStateToProps = (state: AppState) => ({
    streamDetailsModalData: getPlayer(state)?.streamDetailsModalData
 });

const mapDispatchToProps = {
    processNewStream,
    clearProcessingNewStream,
    addNewStream,
    reportProcessedStream,
    openStreamDetailModal,
    editStream
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StreamDetailsModalProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & {};

const StreamDetailsModal: FunctionComponent<StreamDetailsModalProps> = (props) => {
    const { classes } = props;
    const { streamDetailsModalData } = props;
    const { addNewStream, editStream } = props;
    const { processNewStream, clearProcessingNewStream, reportProcessedStream, openStreamDetailModal } = props;
    const authContext = useAuth0();

    const isOpen = streamDetailsModalData?.isModalOpen === true;
    const isProcessing = streamDetailsModalData?.isProcessing;
    const isStreamLoaded = streamDetailsModalData?.stream != null;
    const isStreamReported = streamDetailsModalData?.isReported;
    const isEditing = streamDetailsModalData?.editStreamId != null && streamDetailsModalData?.editStreamId !== 0;

    var formState = FormState.create({
        input: { value: streamDetailsModalData?.input, required: true, maxLength: 4000 },
        title: { value: streamDetailsModalData?.title, maxLength: 255 }
    });

    const isInputChanged = streamDetailsModalData?.input !== formState.field('input').value;
    const isCorrectStreamLoaded = isStreamLoaded && !isInputChanged && !isProcessing;

    const onProcessClick = () => {
        if(formState.validate('input')) {
            const userInput = formState.field('input').value;
            const userTitle = formState.field('title').value;
            processNewStream(authContext, userInput, userTitle);
        }
    };

    const onInputFieldPaste = (event: React.ClipboardEvent) => {
        const inputField = formState.field('input');

        if(!inputField.value) {
            const pastedValue = event.clipboardData.getData('Text');
            event.stopPropagation();
            event.preventDefault();
            inputField.onChange({ target: { value: pastedValue } });
            onProcessClick();
        }
    };

    const onClose = () => {
        openStreamDetailModal(false);

        if(isEditing) {
            setTimeout(() => {
                clearProcessingNewStream();
                formState.clearForm();
            }, 200);
        }
    };

    const onCancelClick = () => {
        openStreamDetailModal(false);
        setTimeout(() => {
            clearProcessingNewStream();
            formState.clearForm();
        }, 200);
    };

    const onAddStreamClick = () => {
        if(formState.validate('title')) {
            const userTitle = formState.field('title').value;
            
            if(isEditing) editStream(authContext, userTitle);
            else addNewStream(authContext, userTitle);
            
            onCancelClick();
        }
    };
    
    const onReportStreamClick = (event: React.SyntheticEvent) => {
        reportProcessedStream(authContext);
        event.preventDefault();
    };

    return (
        <Dialog maxWidth='sm' open={isOpen} onClose={onClose} fullWidth>
            <DialogTitle id='form-dialog-title'>{isEditing ? 'Edit stream' : 'Add new stream'}</DialogTitle>
            <DialogContent>
                <Box display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='row'>
                        <TextField
                            id='input'
                            label='URL address'
                            value={formState.field('input').value}
                            onChange={formState.field('input').onChange}
                            error={formState.field('input').error}
                            helperText={formState.field('input').helperText}
                            onPaste={onInputFieldPaste}
                            variant='outlined'
                            margin='dense'
                            fullWidth
                            autoFocus
                            required
                        />
                        <Box ml={1} mt={1.25}>
                            <Button color='primary' variant='outlined' startIcon={<SearchIcon />} disabled={isProcessing} onClick={onProcessClick}>
                                Process
                            </Button>
                        </Box>
                    </Box>
                    {isStreamLoaded &&
                        <Box>
                            <TextField
                                id='title'
                                label='Title'
                                value={formState.field('title').value}
                                onChange={formState.field('title').onChange}
                                error={formState.field('title').error}
                                helperText={formState.field('title').helperText}
                                variant='outlined'
                                margin='dense'
                                fullWidth
                            />
                        </Box>
                    }

                    {isProcessing &&
                        <Box mt={2} mb={1} mx='auto'><CircularProgress /></Box>
                    }
                    {isCorrectStreamLoaded &&
                        <Box my={1} display="flex" justifyContent="center">
                            <Paper elevation={3} className={classes.streamContainer}>
                                <Box className={classes.streamMedia}>
                                    <StreamPlayerSelector stream={streamDetailsModalData.stream} />
                                </Box>
                            </Paper>
                        </Box>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                {isCorrectStreamLoaded &&
                    (isStreamReported ? 
                    <Typography variant='body2'>
                        Thanks for feedback!
                    </Typography> : 
                    <Link href='#' onClick={onReportStreamClick} variant='body2' color='error' underline='hover'>
                        Report an incorrect stream
                    </Link>)
                }
                <Button onClick={onCancelClick} color='secondary' variant='contained'>
                    Cancel
                </Button>
                <Button onClick={onAddStreamClick} color='primary' variant='contained' disabled={!isCorrectStreamLoaded}>
                    {isEditing ? 'Edit stream' : 'Add stream'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connector(withStyles(styles)(StreamDetailsModal));