import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { getAllStreams } from '../../selectors/playerSelectors';
import { setStreamSynchronization, toggleStreamSynchronization } from '../../actions/streamSynchronizationActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import { AppState } from '../../../site/types/appState';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import { abs } from 'mathjs';
import { Stream } from '../../types/multistream';

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%'
    },
    listItemText: {
        marginRight: theme.spacing(2)
    },
    noTextTransform: {
        textTransform: 'none'
    },
    delayButtonSpacer: {
        minWidth: 0,
        maxWidth: theme.spacing(2),
        width: '100%'
    }
});

const mapStateToProps = (state: AppState) => ({
    supportedStreams: getAllStreams(state).filter(x => x.state.synchronizationSupport)
});

const mapDispatchToProps = {
    setStreamSynchronization,
    toggleStreamSynchronization
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type SynchronizationSettingsProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & {};

const DelayNumberInput = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            style={{ width: '10.1ch' }}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            decimalScale={3}
            allowedDecimalSeparators={['.', ',']}
            isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue == null || abs(floatValue) < 1000000;
            }}
        />
    );
}

const SynchronizationSettings: FunctionComponent<SynchronizationSettingsProps> = (props) => {
    const { classes } = props;
    const { supportedStreams } = props;
    const { setStreamSynchronization, toggleStreamSynchronization } = props;

    const [selectedStreamId, setSelectedStreamId] = React.useState<number>();
    const [isDelayFieldFocused, setIsDelayFieldFocused] = React.useState(false);

    const toggleStreamSynchronizationSetting = (streamId: number, checked: boolean) => {
        toggleStreamSynchronization(streamId, checked, new Date().getTime());

        if (checked && selectedStreamId == null) {
            setSelectedStreamId(streamId);
        }
        else if (!checked && selectedStreamId === streamId) {
            setSelectedStreamId(null);
        }
    };

    const getStreamDelay = (stream: Stream) => {
        if (stream.synchronization != null) {
            return stream.synchronization / 1000;
        }
        else if (stream.state.synchronizationShelve != null) {
            return stream.state.synchronizationShelve / 1000;
        }
        else return 0;
    }

    const noSelectedStream = selectedStreamId == null;

    const getSelectedStreamDelay = () => {
        if (!noSelectedStream) {
            const delay = getStreamDelay(supportedStreams.get(selectedStreamId));
            return delay === 0 && isDelayFieldFocused ? null : delay;
        }
        else return 0;
    };

    const setSelectedStreamDelay = (newValueMs: number) => {
        if (!noSelectedStream) {
            setStreamSynchronization(selectedStreamId, newValueMs, new Date().getTime());
        }
    };

    const increaseSelectedStreamDelay = (deltaMs: number) => {
        if (!noSelectedStream) {
            const currentSynchronizationValue = supportedStreams.get(selectedStreamId).synchronization;
            const newSynchronizationDelay = currentSynchronizationValue != null ? currentSynchronizationValue + deltaMs : deltaMs;
            setStreamSynchronization(selectedStreamId, newSynchronizationDelay, new Date().getTime());
        }
    };

    const onDelayFieldFocus = () => {
        setIsDelayFieldFocused(true);
    };

    const onDelayFieldBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsDelayFieldFocused(false);
        setSelectedStreamDelay(Number(event.target.value) * 1000);
    };

    const onDelayFieldKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setSelectedStreamDelay(Number((event.target as HTMLInputElement).value) * 1000);
        }
    };

    return (
        <div className={classes.root}>
            {supportedStreams && supportedStreams.length > 0 ?
                <List>
                    {supportedStreams.map(stream =>
                        <ListItem
                            key={stream.id}
                            button
                            selected={selectedStreamId === stream.id}
                            onClick={() => setSelectedStreamId(stream.id)}
                            dense
                            disabled={stream.synchronization == null}
                        >
                            <ListItemText
                                className={classes.listItemText}
                                primary={stream.title}
                                secondary={stream.url}
                            />
                            <Box pr={4}>
                                <Typography variant='body1'>
                                    Delay: {getStreamDelay(stream) > 0 ? '+' : ''}{getStreamDelay(stream)} s
                            </Typography>
                            </Box>
                            <ListItemSecondaryAction>
                                <Switch edge='end' checked={stream.synchronization != null} onChange={(_, checked) => toggleStreamSynchronizationSetting(stream.id, checked)} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>
                :
                <Box display='flex' justifyContent='center' alignItems='center'>
                    <Typography variant='subtitle1'>
                        None of the added streams is supporting synchronization
                    </Typography>
                </Box>
            }

            <Box mt={4} display='flex' justifyContent='center' alignItems='center'>
                <Box mr={2} ml={10}>
                    <TextField
                        id='delay'
                        label='Delay (seconds)'
                        variant='outlined'
                        size='small'
                        value={getSelectedStreamDelay()}
                        InputProps={{
                            inputComponent: DelayNumberInput
                        }}
                        disabled={noSelectedStream}
                        onFocus={onDelayFieldFocus}
                        onBlur={onDelayFieldBlur}
                        onKeyUp={onDelayFieldKeyUp}
                    />
                </Box>
                <Button variant='outlined' size='small' color='secondary' disabled={noSelectedStream} onClick={() => setSelectedStreamDelay(0)}>Reset</Button>
            </Box>
            <Box mt={2} display='flex' justifyContent='center' alignItems='center'>
                <Button variant='outlined' size='small' className={classes.noTextTransform} disabled={noSelectedStream} onClick={() => increaseSelectedStreamDelay(-5000)}>-5 s</Button>
                <Box className={classes.delayButtonSpacer} />
                <Button variant='outlined' size='small' className={classes.noTextTransform} disabled={noSelectedStream} onClick={() => increaseSelectedStreamDelay(-1000)}>-1 s</Button>
                <Box className={classes.delayButtonSpacer} />
                <Button variant='outlined' size='small' className={classes.noTextTransform} disabled={noSelectedStream} onClick={() => increaseSelectedStreamDelay(-500)}>-0.5 s</Button>
                <Box className={classes.delayButtonSpacer} />
                <Button variant='outlined' size='small' className={classes.noTextTransform} disabled={noSelectedStream} onClick={() => increaseSelectedStreamDelay(500)}>+0.5 s</Button>
                <Box className={classes.delayButtonSpacer} />
                <Button variant='outlined' size='small' className={classes.noTextTransform} disabled={noSelectedStream} onClick={() => increaseSelectedStreamDelay(1000)}>+1 s</Button>
                <Box className={classes.delayButtonSpacer} />
                <Button variant='outlined' size='small' className={classes.noTextTransform} disabled={noSelectedStream} onClick={() => increaseSelectedStreamDelay(5000)}>+5 s</Button>
            </Box>
        </div>
    );
}

export default connector(withStyles(styles)(SynchronizationSettings));