import React, { FunctionComponent } from 'react';
import { Stream } from '../../types/multistream';
import YoutubeStream from './streamPlayers/YoutubeStream';
import TwitchChat from './streamPlayers/TwitchChat';
import TwitchStream from './streamPlayers/TwitchStream';
import ReactPlayerStream from './streamPlayers/ReactPlayerStream';
import TwitterStream from './streamPlayers/TwitterStream';
import VideoFileStream from './streamPlayers/VideoFileStream';
import AudioFileStream from './streamPlayers/AudioFileStream';
import WebsiteStream from './streamPlayers/WebsiteStream';


type StreamPlayerSelectorProps = {
    stream: Stream
};

const StreamPlayerSelector: FunctionComponent<StreamPlayerSelectorProps> = (props) => {
    const { stream } = props;

    if(!stream) {
        return null;
    }

    const { streamType, sourceType, sourceKey } = stream;

    switch (streamType) {
        case 'MultiVideo': return <StreamPlayerSelector stream={{ ...stream.state.multiVideoStream, id: stream.id }} />
        case 'Youtube': return <YoutubeStream streamId={stream.id} sourceKey={sourceKey} />;
        case 'Twitch': switch (sourceType) {
            case 'chat': return <TwitchChat streamId={stream.id} sourceKey={sourceKey} />;
            default: return <TwitchStream streamId={stream.id} sourceType={sourceType} sourceKey={sourceKey} />;
        }
        case 'ReactPlayer': return <ReactPlayerStream streamId={stream.id} url={sourceKey} />;
        case 'Twitter': return <TwitterStream streamId={stream.id} sourceType={sourceType} sourceKey={sourceKey} />;
        case 'VideoFile': return <VideoFileStream streamId={stream.id} sourceKey={sourceKey} />;
        case 'AudioFile': return <AudioFileStream streamId={stream.id} sourceKey={sourceKey} />;
        case 'Website': return <WebsiteStream sourceKey={sourceKey} />;
        default: return null;
    }
}

export default StreamPlayerSelector;