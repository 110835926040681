import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { TwitterTimelineEmbed, TwitterMomentShare } from 'react-twitter-embed';
import { setStreamMinimumFrameSize } from '../../../actions/streamActions';


const mapDispatchToProps = {
    setStreamMinimumFrameSize
};

const connector = connect(null, mapDispatchToProps);

type TwitterStreamProps = ConnectedProps<typeof connector> & {
    streamId: number;
    sourceType: string;
    sourceKey: string;
};

const TwitterStream: FunctionComponent<TwitterStreamProps> = (props) => {
    const { streamId, sourceType, sourceKey } = props;
    const { setStreamMinimumFrameSize } = props;

    const twitterEmbedRef = React.useRef(null);

    React.useEffect(() => {
        streamId !== 0 && setStreamMinimumFrameSize(streamId, { width: 182, height: 202 });
    }, [streamId, setStreamMinimumFrameSize]);

    React.useEffect(() => {
        const embedContainer = twitterEmbedRef?.current?.refs?.embedContainer;

        if(embedContainer && embedContainer.style.length === 0) {
            embedContainer.style = 'height: 100%; background-color: white;';
        }
    });

    const twitterEmbedOptions = {
        height: '100%'
    };

    switch (sourceType) {
        case 'profile':
            return <TwitterTimelineEmbed sourceType="profile" screenName={sourceKey} noFooter ref={twitterEmbedRef} options={twitterEmbedOptions} />;
        case 'list':
            return <TwitterTimelineEmbed sourceType="list" id={sourceKey} noFooter ref={twitterEmbedRef} options={twitterEmbedOptions} />;
        case 'listName':
            const [ user, list ] = sourceKey.split('/');
            return <TwitterTimelineEmbed sourceType="list" ownerScreenName={user} slug={list} noFooter ref={twitterEmbedRef} options={twitterEmbedOptions} />;
        case 'likes':
            return <TwitterTimelineEmbed sourceType="likes" screenName={sourceKey} noFooter ref={twitterEmbedRef} options={twitterEmbedOptions} />;
        case 'collection':
            return <TwitterTimelineEmbed sourceType="collection" id={sourceKey} noFooter ref={twitterEmbedRef} options={twitterEmbedOptions} />;
        case 'moment':
            return <TwitterMomentShare momentId={sourceKey} ref={twitterEmbedRef} options={{limit: 1}} />;
        default:
            return null;
    }
}

export default connector(TwitterStream);