import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import StreamsContainer from './StreamsContainer';
import MultistreamSettings from './multistreamSettings/MultistreamSettings';
import StreamDetailsModal from './multistreamSettings/StreamDetailsModal';
import { getMultistream, getPlayerFlags } from '../selectors/playerSelectors';
import { AppState } from '../../site/types/appState';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SnapPane from './SnapPane';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link as RouterLink } from 'react-router-dom';

const mapStateToProps = (state: AppState) => ({
    multistream: getMultistream(state),
    errorDuringMultistreamFetch: getPlayerFlags(state)?.errorDuringMultistreamFetch === true
 });
 
const connector = connect(mapStateToProps);

type MultistreamPlayerProps = ConnectedProps<typeof connector>;

const MultistreamPlayer: FunctionComponent<MultistreamPlayerProps> = (props) => {
    const { multistream, errorDuringMultistreamFetch } = props;
    const [isMouseMoving, setMouseMoving] = React.useState(false);
    const isOwner = multistream?.isOwner === true;

    React.useEffect(() => {
        setMouseMoving(false);
    }, [isMouseMoving]);

    const onMouseMove = () => {
        setMouseMoving(true);
    };

    return (
        <React.Fragment>
            {multistream ?
                <React.Fragment>   
                    <StreamsContainer onMouseMove={onMouseMove} />
                    <SnapPane />
                    <MultistreamSettings isMouseMoving={isMouseMoving} isOwner={isOwner} />
                    <StreamDetailsModal />
                </React.Fragment>
            : !errorDuringMultistreamFetch ?
                <Backdrop open={true} >
                    <CircularProgress />
                </Backdrop>
            : <MultistreamNotFoundDialog />
            }
        </React.Fragment>
    );
}

const MultistreamNotFoundDialog: FunctionComponent = () => {
    return (
        <Dialog open>
            <DialogTitle>Multistream not found</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Looks like there is no Multistream with that address.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <RouterLink to='/' style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <Button color='primary' variant='contained'>
                        Home page
                    </Button>
                </RouterLink>
            </DialogActions>
        </Dialog>
    )
};

export default connector(MultistreamPlayer);