import React, { FunctionComponent } from 'react';
import CardsDeck from '../components/cards/CardsDeck'
import MultistreamCard from '../components/cards/MultistreamCard'

const Browse: FunctionComponent = () => {
    return (
        <div>
            <CardsDeck id='latestMultistreams' url='/api/multistreams' render={card => <MultistreamCard keyId={card.key} {...card} />} />
        </div>
    );
}

export default Browse;