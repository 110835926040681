import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { setStreamConstantFrameSize, setStreamMinimumFrameSize } from '../../../actions/streamActions';
import { setStreamSynchronizationSupport } from '../../../actions/streamSynchronizationActions';
import ReactPlayerStream from './ReactPlayerStream';

const mapDispatchToProps = {
    setStreamConstantFrameSize,
    setStreamMinimumFrameSize,
    setStreamSynchronizationSupport
};

const connector = connect(null, mapDispatchToProps);

type AudioFileStreamProps = ConnectedProps<typeof connector> & {
    streamId: number;
    sourceKey: string;
};

const AudioFileStream: FunctionComponent<AudioFileStreamProps> = (props) => {
    const { streamId, sourceKey } = props;
    const { setStreamConstantFrameSize, setStreamMinimumFrameSize, setStreamSynchronizationSupport } = props;

    React.useEffect(() => {
        if(streamId !== 0) {
            setStreamConstantFrameSize(streamId, { height: 58 });
            setStreamMinimumFrameSize(streamId, { width: 189 });
            setStreamSynchronizationSupport(streamId, true);
        }
    }, [streamId, setStreamConstantFrameSize, setStreamMinimumFrameSize, setStreamSynchronizationSupport]);

    return (
        <ReactPlayerStream streamId={streamId} url={sourceKey} />
    );
}

export default connector(AudioFileStream);