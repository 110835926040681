import React, { FunctionComponent } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';
import { Route } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Menu from '../components/site/Menu';
import Footer from '../components/site/Footer';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import CookiesPolicy from './CookiesPolicy';
import PrivacyPolicy from './PrivacyPolicy';
import Browse from './Browse';
import Games from './Games';
import Create from './Create';
import BrowseTabs from '../components/site/BrowseTabs'
import User from './User';
import UserMultistreams from './UserMultistreams';
import SearchResults from './SearchResults';

const menuOptions = [
    { label: 'Home', link: '/' },
    { label: 'About', link: '/about' },
    { label: 'Browse', link: '/browse' },
    { label: 'Games', link: '/games' },
    { label: 'Create', link: '/create' },
    { label: 'Contact', link: '/contact' },
    { label: 'Your multistreams', link: '/multistreams', isPrivate: true }
];

const siteTheme = createMuiTheme({
    palette: {
        background: {
            default: '#ecf0f5'
        }
    }
});

type SiteAppProps = {
    version: string;
};

const SiteApp: FunctionComponent<SiteAppProps> = (props) => {
    const { version } = props;

    return (
        <React.Fragment>
            <ThemeProvider theme={siteTheme}>
                <CssBaseline />
                <Menu websiteName='Multistream.co' menuOptions={menuOptions} />

                <Box my={12}>
                    <Route path={['/browse', '/games']} component={BrowseTabs} />

                    <Route exact path='/' component={Home} />
                    <Route path='/about' component={About} />
                    <Route path='/contact' component={Contact} />
                    <Route path='/cookies' component={CookiesPolicy} />
                    <Route path='/privacy' component={PrivacyPolicy} />
                    <Route path='/browse' component={Browse} />
                    <Route path='/games' component={Games} />
                    <Route path='/create' component={Create} />
                    <Route path='/user' component={User} />
                    <Route path='/multistreams' component={UserMultistreams} />
                    <Route path='/search' component={SearchResults} />
                </Box>

                <Footer version={version} />
            </ThemeProvider>
        </React.Fragment>
    );
}

export default SiteApp;