import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getStream } from '../../selectors/playerSelectors';
import StreamFrame from './StreamFrame';
import StreamMask from './StreamMask';
import StreamPlayerSelector from './StreamPlayerSelector';
import StreamSettings from './StreamSettings';
import { AppState } from '../../../site/types/appState';

type OwnProps = {
    streamId: number;
    componentId: number;
};

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
    stream: getStream(state, ownProps.streamId)
});

const connector = connect(mapStateToProps);

type StreamProps = ConnectedProps<typeof connector> & OwnProps;

const Stream: FunctionComponent<StreamProps> = (props) => {
    const { stream, streamId, componentId } = props;
    
    return (
        <StreamFrame 
                streamId={streamId}
                componentId={componentId}
                isInteractive >
            <StreamPlayerSelector stream={stream} />
            <StreamMask streamId={streamId} componentId={componentId} />
            <StreamSettings streamId={streamId} componentId={componentId}  />
        </StreamFrame>
    );
};

export default connector(Stream);