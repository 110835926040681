import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getNotifications } from '../../selectors/siteSelectors';
import { removeNotification } from '../../actions/siteActions';
import { AppState } from '../../types/appState';
import { Notification } from '../../types/notification';


const mapStateToProps = (state: AppState) => ({
    notifications: getNotifications(state)
 });

const mapDispatchToProps = {
    removeNotification
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type NotifierProps = ConnectedProps<typeof connector> & {};

const Notifier: FunctionComponent<NotifierProps> = (props) => {
    const { notifications } = props;
    const { removeNotification } = props;
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        notifications && notifications.forEach((notification: Notification) => {
            enqueueSnackbar(notification.message, {
                variant: notification.type
            });

            removeNotification(notification.key);
        });
    });

    return null;
}

export default connector(Notifier);