import React, { FunctionComponent } from 'react';
import { TwitchChat as ReactTwitchEmbedChat } from 'react-twitch-embed';
import StopIFrameFlash from '../../../utils/StopIFrameFlash';


type TwitchChatProps = {
    streamId: number;
    sourceKey: string;
};

const TwitchChat: FunctionComponent<TwitchChatProps> = (props) => {
    const { streamId, sourceKey } = props;

    return (
        <StopIFrameFlash>
            <ReactTwitchEmbedChat id={'twitch-chat-embed_' + streamId} channel={sourceKey} theme='dark' width='100%' height='100%' />
        </StopIFrameFlash>
    );
}

export default TwitchChat;