import { AppState } from '../types/appState';
import { SiteState } from '../types/siteState';
import { Notification } from '../types/notification';
import { SiteFlags } from '../types/siteFlags';
import { CardsData } from '../types/cardsData';

type State = AppState | SiteState;

const isSiteState = (state: State): state is SiteState => {
    return !state.hasOwnProperty('site');
}

export const getSite = (state: State): SiteState => {
    return isSiteState(state) ? state : state.site;
};

export const getCardsData = (state: State, id: string): CardsData => {
    const site = getSite(state);
    return site && site.cards && site.cards[id];
};

export const getNotifications = (state: State): Array<Notification> => {
    const site = getSite(state);
    return site && site.notifications;
};

export const getSiteFlags = (state: State): SiteFlags => {
    const site = getSite(state);
    return site && site.siteFlags;
};