import React, { ErrorInfo, PropsWithChildren } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { reportFrontendError } from '../../actions/siteActions';
import { Auth0Context, Auth0ContextInterface } from '@auth0/auth0-react';

const mapDispatchToProps = {
  reportFrontendError
};

const connector = connect(null, mapDispatchToProps);

type ErrorReportingBoundaryProps = ConnectedProps<typeof connector> & PropsWithChildren<{}>;

class ErrorReportingBoundary extends React.Component<ErrorReportingBoundaryProps, {}> {
  static contextType = Auth0Context;

  componentDidCatch(error: Error, _info: ErrorInfo) {
    const authContext: Auth0ContextInterface = this.context;
    this.props.reportFrontendError(authContext, error);
  }

  render() {
    return this.props.children;
  }
}

export default connector(ErrorReportingBoundary);