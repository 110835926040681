import { createReducer } from '../../utils/functions/reduxUtils';
import { types } from '../actions/componentActions';
import { getComponent, getAllComponents, getVisibleComponents, getPlayer, getAllStreams, getLayout } from '../selectors/playerSelectors';
import { PlayerState } from '../types/playerState';
import { ComponentPayload } from '../types/payloadTypes';
import { ComponentState } from '../types/componentState';
import Position from '../../utils/functions/Position';
import Size from '../../utils/functions/Size';
import { SnapPane } from '../types/snapPane';
import { LayoutState } from '../types/layoutState';
import { Component } from '../types/multistream';

export const componentReducer = createReducer<PlayerState>({
    [types.UPDATE_COMPONENT_POSITION]: (draft, payload: ComponentPayload<Position>) => {
        const component = getComponent(draft, payload.componentId);

        if(component != null) {
            component.position = payload.data;
        }
    },
    [types.UPDATE_COMPONENT_SIZE]: (draft, payload: ComponentPayload<Size>) => {
        const component = getComponent(draft, payload.componentId);

        if(component != null) {
            component.size = payload.data;
        }
    },
    [types.SET_COMPONENT_FRAME_ACTIVE]: (draft, payload: ComponentPayload<boolean>) => {
        disableAllComponentFrames(draft);

        if(payload.data) {
            const component = getComponent(draft, payload.componentId);

            if(component != null && component.state != null) {
                component.state.isFrameActive = true;
            }
        }
    },
    [types.SWAP_COMPONENT_WITH_LARGEST]: (draft, componentId: number) => {
        const visibleComponents = getVisibleComponents(draft);
        const targetComponent = getComponent(draft, componentId);
        
        if(targetComponent != null) {
            let largestComponent: Component = null;
            let largestArea: number = 0;

            visibleComponents.forEach(x => {
                const area = x.size.width * x.size.height;

                if(area > largestArea) {
                    largestComponent = x;
                    largestArea = area;
                }
            });

            if(largestComponent != null && largestComponent.id !== targetComponent.id)
            {
                const targetComponentOrder = targetComponent.order;
                targetComponent.order = largestComponent.order;
                largestComponent.order = targetComponentOrder;
            }
        }
    },
    [types.UPDATE_COMPONENT_STATE]: (draft, payload: ComponentPayload<ComponentState>) => {
        const component = getComponent(draft, payload.componentId);

        if(component != null) {
            component.state = { ...component.state, ...payload.data };
        }
    },
    [types.UPDATE_SNAP_PANE]: (draft, payload: SnapPane) => {
        const player = getPlayer(draft);
        player.snapPane = payload;
    },
    [types.MERGE_COMPONENTS]: (draft, payload: ComponentPayload<number>) => {
        const sourceComponent = getComponent(draft, payload.componentId);
        const targetComponent = getComponent(draft, payload.data);

        if(sourceComponent != null && targetComponent != null) {
            const allStreams = getAllStreams(draft);
            const allSourceStreams = allStreams.filter(x => x.order === sourceComponent.order);
            const allTargetStreams = allStreams.filter(x => x.order === targetComponent.order);
    
            allTargetStreams.forEach(x => x.isHidden = true);
            allSourceStreams.forEach(x => x.order = targetComponent.order);
        }
    },
    [types.UPDATE_LAYOUT_STATE]: (draft, payload: LayoutState) => {
        const layout = getLayout(draft);

        if(layout != null) {
            layout.state = { ...layout.state, ...payload };
        }
    },
});

const disableAllComponentFrames = (draft: PlayerState) => {
    var components = getAllComponents(draft);
    components?.forEach((component) => {
        component.state.isFrameActive = false;
    });
};
