import React, { FunctionComponent } from 'react';
import ReactPlayer from 'react-player';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../../site/types/appState';
import { getStream, generateCalculateRequestedSynchronizationTimeMsFunction } from '../../../selectors/playerSelectors';
import { triggerStreamPlayerEvent } from '../../../actions/streamSynchronizationActions';
import { PlayerEvent } from '../../../types/streamState';

type OwnProps = {
    streamId: number;
    url: string;
    onReady?: () => void; //required by StopIFrameFlash
    style?: Object; //required by StopIFrameFlash
};

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
    stream: getStream(state, ownProps.streamId),
    calculateRequestedSynchronizationTimeMs: generateCalculateRequestedSynchronizationTimeMsFunction(state, ownProps.streamId)
});

const mapDispatchToProps = {
    triggerStreamPlayerEvent
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ReactPlayerStreamProps = ConnectedProps<typeof connector> & OwnProps;

const ReactPlayerStream: FunctionComponent<ReactPlayerStreamProps> = (props) => {
    const { streamId, url } = props;
    const { calculateRequestedSynchronizationTimeMs } = props;
    const { isPaused, volume, isUnmuted } = props.stream?.state || {};
    const { triggerStreamPlayerEvent } = props;
    const { style, onReady } = props;
    const reactPlayerRef = React.useRef(null);

    React.useEffect(() => { 
        if(calculateRequestedSynchronizationTimeMs) {
            const synchronizeToSeconds = calculateRequestedSynchronizationTimeMs(new Date().getTime()) / 1000;
            reactPlayerRef.current?.seekTo(synchronizeToSeconds, 'seconds');
            onPlayerEvent('onSync');
        }
    });

    const onPlayerEvent = (playerEvent: PlayerEvent) => {
        triggerStreamPlayerEvent(streamId, playerEvent, reactPlayerRef.current?.getCurrentTime() * 1000, new Date().getTime());
    };

    return (
        <ReactPlayer
            ref={reactPlayerRef}
            url={url}
            light={false}
            width='100%'
            height='100%'
            controls
            playing={!isPaused}
            volume={volume}
            muted={!isUnmuted}
            style={style}
            onReady={onReady}
            onStart={() => onPlayerEvent('onStart')}
            onPlay={() => onPlayerEvent('onPlay')}
            onPause={() => onPlayerEvent('onPause')}
            onBuffer={() => onPlayerEvent('onBuffer')}
        />
    );
}

export default connector(ReactPlayerStream);