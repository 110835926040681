import React, { FunctionComponent } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Notifier from '../components/site/Notifier';
import { Provider } from 'react-redux'
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import playerReducers from '../../player/reducers/playerReducer';
import siteReducers from '../reducers/siteReducer';
import { SnackbarProvider } from 'notistack';
import { Auth0Provider } from '@auth0/auth0-react';
import ErrorReportingBoundary from '../components/site/ErrorReportingBoundary';
import ReactGA from 'react-ga';
import SiteApp from './SiteApp';
import PlayerApp from './PlayerApp';

const version = '2.0.3';

const composeEnhancers = composeWithDevTools({});

const store = createStore(combineReducers({
    player: playerReducers,
    site: siteReducers
}), composeEnhancers(applyMiddleware(thunk)));

if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
    ReactGA.pageview(window.location.pathname + window.location.search);
}

const App: FunctionComponent = () => {
    return (
        <Provider store={store}>
            <Auth0Provider
                domain={process.env.REACT_APP_AUTH_DOMAIN}
                clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
                audience={process.env.REACT_APP_AUTH_AUDIENCE}
                cacheLocation='localstorage'
                useRefreshTokens
            >
                <ErrorReportingBoundary>
                    <SnackbarProvider maxSnack={3} autoHideDuration={2000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        <Notifier />
                        <BrowserRouter>
                            <Switch>
                                <Route path={['/player', '/p', '/game', '/g']} component={PlayerApp} />
                                <Route render={(props) => (<SiteApp {...props} version={version} />)} />
                            </Switch>
                        </BrowserRouter>
                    </SnackbarProvider>
                </ErrorReportingBoundary>
            </Auth0Provider>
        </Provider>
    );
}

export default App;
