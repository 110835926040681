import { createReducer } from '../../utils/functions/reduxUtils';
import { types } from '../actions/streamActions';
import { getStream, getPlayer, getMultiVideoStreamsQueue, getAllStreams } from '../selectors/playerSelectors';
import { PlayerState } from '../types/playerState';
import { MultiVideoStreamsPayload } from '../types/multiVideoStreams';
import { PlayerEvent, StreamState } from '../types/streamState';
import { StreamPayload } from '../types/payloadTypes';

export const streamReducer = createReducer<PlayerState>({
    [types.UPDATE_STREAM_STATE]: (draft, payload: StreamPayload<StreamState>) => {
        const stream = getStream(draft, payload.streamId);

        if (stream != null) {
            stream.state = { ...stream.state, ...payload.data };
        }
    },
    [types.HIDE_STREAM]: (draft, payload: StreamPayload<boolean>) => {
        const stream = getStream(draft, payload.streamId);

        if (stream != null) {
            stream.isHidden = payload.data;
        }
    },
    [types.ADD_NEW_MULTIVIDEO_STREAMS]: (draft, payload: MultiVideoStreamsPayload) => addNewMultiVideoStreams(draft, payload),
    [types.ASSIGN_NEXT_MULTIVIDEO_STREAM]: (draft, streamId: number) => {
        const stream = getStream(draft, streamId);

        if (stream != null) {
            const multiVideoStreamsQueue = getMultiVideoStreamsQueue(draft, stream.sourceType, stream.sourceKey);

            if (multiVideoStreamsQueue?.streams?.length > 0) {
                stream.state.multiVideoStream = multiVideoStreamsQueue.streams.shift();
            }
            else stream.state.multiVideoStream = null;
        }
    },
    [types.TOGGLE_STREAM_IN_MULTICOMPONENT]: (draft, streamId: number) => {
        const stream = getStream(draft, streamId);

        if (stream != null) {
            const otherMultiComponentStreams = getAllStreams(draft).filter(x => x.id !== stream.id && x.order === stream.order);

            stream.isHidden = false;
            otherMultiComponentStreams.forEach(x => x.isHidden = true);
        }
    },
    [types.SET_STREAM_SYNCHRONIZATION]: (draft, payload: StreamPayload<number>) => {
        const stream = getStream(draft, payload.streamId);

        if (stream != null) {
            stream.synchronization = payload.data;
        }
    },
    [types.TOGGLE_STREAM_SYNCHRONIZATION]: (draft, payload: StreamPayload<boolean>) => {
        const stream = getStream(draft, payload.streamId);

        if (stream != null) {
            const enabledSynchronization = payload.data;

            if (enabledSynchronization && stream.synchronization == null) {
                if (stream.state.synchronizationShelve != null) {
                    stream.synchronization = stream.state.synchronizationShelve;
                }
                else {
                    stream.synchronization = 0;
                }
            }
            else if (!enabledSynchronization && stream.synchronization != null) {
                stream.state.synchronizationShelve = stream.synchronization;
                stream.synchronization = null;
            }
        }
    },
    [types.ADD_PLAYER_EVENT]: (draft, payload: StreamPayload<PlayerEvent>) => {
        const playerEventsSize = 5;
        const stream = getStream(draft, payload.streamId);
        const playerEvent = payload.data;

        if (stream != null) {
            if (stream.state.playerEvents == null) {
                stream.state.playerEvents = [playerEvent];
            }
            else if (stream.state.playerEvents.length < playerEventsSize) {
                stream.state.playerEvents.push(playerEvent);
            }
            else {
                stream.state.playerEvents.shift();
                stream.state.playerEvents.push(playerEvent);
            }
        }
    }
});

const addNewMultiVideoStreams = (draft: PlayerState, payload: MultiVideoStreamsPayload) => {
    const player = getPlayer(draft);

    if (player.multiVideoStreams == null) player.multiVideoStreams = { queues: {} };
    if (player.multiVideoStreams.queues[payload.type] == null) player.multiVideoStreams.queues[payload.type] = {};
    if (player.multiVideoStreams.queues[payload.type][payload.key] == null) {
        player.multiVideoStreams.queues[payload.type][payload.key] = {
            streams: [],
            cursor: null,
            isRequesting: false,
            isExhausted: false
        };
    }

    const queue = player.multiVideoStreams.queues[payload.type][payload.key];

    if (payload.isRequesting) {
        queue.isRequesting = true;
    }
    else {
        queue.streams.push(...payload.streams);
        queue.cursor = payload.cursor;
        queue.isRequesting = false;
        queue.isExhausted = payload.streams.length === 0;
    }
};
