import React, { FunctionComponent } from 'react';
import CardsDeck from '../components/cards/CardsDeck'
import GameCard from '../components/cards/GameCard'

const Games: FunctionComponent = () => {
    return (
        <div>
            <CardsDeck id='topGames' url='/api/games' render={card => <GameCard key={card.id} {...card} />} />
        </div>
    );
};

export default Games;