import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import withWidth, { isWidthUp, isWidthDown, WithWidth } from '@material-ui/core/withWidth'
import { Theme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CardsDeck from '../components/cards/CardsDeck'
import GameCard from '../components/cards/GameCard'
import MultistreamCard from '../components/cards/MultistreamCard';

const styles = (theme: Theme) => createStyles({
    jumbotron: {
        backgroundColor: '#dce3e6'
    },
    logoFont: {
        fontFamily: 'KarmaFuture',
        [theme.breakpoints.up('md')]: {
            fontSize: '6vw'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: theme.spacing(15)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.spacing(7)
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.spacing(6)
        }
    },
    logoButton: {
        margin: theme.spacing(1)
    },
    sectionTitle: {
        fontSize: '1.7rem'
    }
});

type HomeProps = WithStyles<typeof styles> & WithWidth & {};

const Home: FunctionComponent<HomeProps> = (props) => {
    const { classes } = props;
    const { width } = props;

    const currentSectionMargin = isWidthUp('md', width) ? 16 : isWidthDown('xs', width) ? 4 : 8;

    return (
        <Box>
            <Container>
                <Paper className={classes.jumbotron} elevation={4}>
                    <Box pt={4} pb={2} display='flex' justifyContent='center'>
                        <span className={classes.logoFont}>Multistream.co</span>
                    </Box>
                    <Box pb={4} display='flex' justifyContent='center'>
                        <Button variant="contained" color="primary" className={classes.logoButton} component={RouterLink} to="/create">
                            Create
                        </Button>
                        <Button variant="contained" color="secondary" className={classes.logoButton} component={RouterLink} to="/browse">
                            Browse
                        </Button>
                    </Box>
                </Paper>
            </Container>
            

            <Box mt={8} pl={currentSectionMargin}>
                <Typography variant="h4" className={classes.sectionTitle}>
                    <b>Popular games</b>
                </Typography>
            </Box>

            <CardsDeck id='recommendedGames' url='/api/games/recommended' render={card => <GameCard key={card.id} {...card} />} maxItems={isWidthUp('md', width) ? 7 : 6} />

            <Box pr={currentSectionMargin} display='flex' justifyContent='flex-end'>
                <Button variant="outlined" color="primary" className={classes.logoButton} component={RouterLink} to="/games">
                    All games
                </Button>
            </Box>


            <Box mt={8} pl={currentSectionMargin}>
                <Typography variant="h4" className={classes.sectionTitle}>
                    <b>Recommended multistreams</b>
                </Typography>
            </Box>

            <CardsDeck id='recommendedMultistreams' url='/api/multistreams/recommended' render={card => <MultistreamCard keyId={card.key} {...card} />} maxItems={10} />
            
            <Box pr={currentSectionMargin} display='flex' justifyContent='flex-end'>
                <Button variant="outlined" color="primary" className={classes.logoButton} component={RouterLink} to="/browse">
                    Browse all
                </Button>
            </Box>

        </Box>
    );
}

export default withWidth()(withStyles(styles)(Home));