export type VectorUnit = 'px'|'%';

export default class Vector2 {
    public x: number;
    public y: number;
    public unit: VectorUnit;

    public static getViewWidth(): number {
        return document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    }

    public static getViewHeight(): number {
        return document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight;
    }

    public constructor(x: number, y: number, unit: VectorUnit) {
        this.x = x;
        this.y = y;
        this.unit = unit;
    }

    public clone(): Vector2 {
        return new Vector2(this.x, this.y, this.unit);
    }

    public add(value: number | Vector2) {
        this.binaryOperation(value, (left, right) => left + right);
    }

    public subtract(value: number | Vector2) {
        this.binaryOperation(value, (left, right) => left - right);
    }

    public multiply(value: number | Vector2) {
        this.binaryOperation(value, (left, right) => left * right);
    }

    public divide(value: number | Vector2) {
        this.binaryOperation(value, (left, right) => left / right);
    }

    public toFixed(fractionDigits: number) {
        this.x = +this.x.toFixed(fractionDigits);
        this.y = +this.y.toFixed(fractionDigits);
    }

    public lengthSquared(): number {
        return this.x * this.x + this.y * this.y;
    }

    public length(): number {
        return Math.sqrt(this.lengthSquared());
    }

    public convertUnitToPercent() {
        if(this.unit === '%') return;

        this.multiply(100);
        this.divide(new Vector2(Vector2.getViewWidth(), Vector2.getViewHeight(), 'px'));
        this.toFixed(4);
        this.unit = '%';
    }

    public convertUnitToPixel() {
        if(this.unit === 'px') return;

        this.multiply(new Vector2(Vector2.getViewWidth(), Vector2.getViewHeight(), 'px'));
        this.divide(100);
        this.toFixed(0);
        this.unit = 'px';
    }

    private binaryOperation(value: number | Vector2, operator: (left: number, right: number) => number) {
        if (typeof (value) === "number") {
            this.x = operator(this.x, value as number);
            this.y = operator(this.y, value as number);
        } else {
            this.x = operator(this.x, (value as Vector2).x);
            this.y = operator(this.y, (value as Vector2).y);
        }
    }
}