import React, { FunctionComponent, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Rnd as ResizeAndDragFrame, DraggableData, RndDragEvent } from "react-rnd";
import { distance } from 'mathjs';
import Position from '../../../utils/functions/Position';
import Size from '../../../utils/functions/Size';
import { AppState } from '../../../site/types/appState';
import { getComponent, getStream, getMultistreamFlags } from '../../selectors/playerSelectors';
import {
    updateComponentPosition, updateComponentSize, tryToSnapComponent, setComponentFrameActive,
    setComponentShowSettings, tryToShowSnapPane
} from '../../actions/componentActions';
import { unmuteOnlyThisStream } from '../../actions/streamActions';
import { showFullStreamMasks, restoreDefaultStreamMasks } from '../../actions/playerActions';

const styles = (theme: Theme) => createStyles({
    frame: {
        backgroundColor: 'black',
        border: '1px dashed #303030',
        borderRadius: '3px'
    },
    activeFrame: {
        border: '1px solid red',
    }
});

const resizeHandleStyles = {
    bottom: { cursor: 'ns-resize' },
    left: { cursor: 'ew-resize' },
    right: { cursor: 'ew-resize' },
    top: { cursor: 'ns-resize' }
};

const defaultMinSize = { width: 100, height: 60 };
const dragTimeThresholdMinMs = 250;
const dragDistanceThreshold = 10;

type OwnProps = {
    streamId: number;
    componentId: number;
    isInteractive?: boolean;
};

const mapStateToProps = (state: AppState, ownProps: OwnProps) => ({
    stream: getStream(state, ownProps.streamId),
    component: getComponent(state, ownProps.componentId),
    embededStreams: getMultistreamFlags(state).embededStreams === true,
    unmuteStreamsOnHover: getMultistreamFlags(state).unmuteStreamsOnHover === true
});

const mapDispatchToProps = {
    updateComponentPosition,
    updateComponentSize,
    showFullStreamMasks,
    restoreDefaultStreamMasks,
    tryToSnapComponent,
    setComponentFrameActive,
    setComponentShowSettings,
    tryToShowSnapPane,
    unmuteOnlyThisStream
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type StreamFrameProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & OwnProps;

const StreamFrame: FunctionComponent<StreamFrameProps> = (props) => {
    const { classes } = props;
    const { streamId, stream, componentId, component } = props;
    const { embededStreams, unmuteStreamsOnHover } = props;
    const { updateComponentPosition, updateComponentSize, tryToSnapComponent, tryToShowSnapPane, setComponentFrameActive, setComponentShowSettings } = props;
    const { unmuteOnlyThisStream } = props;
    const { showFullStreamMasks, restoreDefaultStreamMasks } = props;

    const [dragStartTimeMs, setDragStartTimeMs] = React.useState(0);
    const [browserSize, setBrowserSize] = React.useState([0, 0]);
    const [settingsTimeout, setSettingsTimeout] = React.useState(null);
    const [isMoving, setIsMoving] = React.useState(false);
    const [preDragPositionAndSize, setPreDragPositionAndSize] = React.useState<{ position: Position, size: Size }>(null);

    const framePosition = component.position.convertUnitToPixel();
    const frameSize = { width: component.size.width + '%', height: component.size.height + '%' };
    const constantFrameSize = stream.state.constantFrameSize;

    useEffect(() => {
        const updateBrowserSize = () => {
            setBrowserSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', updateBrowserSize);
        updateBrowserSize();
        return () => window.removeEventListener('resize', updateBrowserSize);
    }, []);

    useEffect(() => {
        if (constantFrameSize) {
            const currentSize = component.size.convertUnitToPixel();
            const newSize = new Size(constantFrameSize.width || currentSize.width, constantFrameSize.height || currentSize.height, 'px');
            updateComponentSize(componentId, newSize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [constantFrameSize, browserSize, componentId, updateComponentSize]);

    const onDragStart = (event: RndDragEvent, _data: DraggableData) => {
        setIsMoving(true);
        hideComponentSettings();
        showFullStreamMasks();
        setDragStartTimeMs(Date.now());

        if (component.state.preSnapSize != null) {
            const pointerPosition = getPointerPosition(event);
            const componentPositionPixel = component.position.convertUnitToPixel();
            const componentSizePixel = component.size.convertUnitToPixel();
            const horizontalFraction = (pointerPosition.left - componentPositionPixel.left) / componentSizePixel.width;
            const verticalFraction = (pointerPosition.top - componentPositionPixel.top) / componentSizePixel.height;
            const preSnapSizePixel = component.state.preSnapSize.convertUnitToPixel();
            const newX = pointerPosition.left - (preSnapSizePixel.width * horizontalFraction);
            const newY = pointerPosition.top - (preSnapSizePixel.height * verticalFraction);

            setPreDragPositionAndSize({ position: component.position, size: component.size });
            updateComponentPosition(componentId, new Position(newX, newY, 'px'));
            updateComponentSize(componentId, component.state.preSnapSize);
        }
    };

    const onDrag = (event: RndDragEvent, _data: DraggableData) => {
        const pointerPosition = getPointerPosition(event);
        tryToShowSnapPane(componentId, pointerPosition);
    };

    const onDragStop = (event: RndDragEvent, data: DraggableData) => {
        restoreDefaultStreamMasks();
        tryToShowSnapPane();
        const dragDurationMs = Date.now() - dragStartTimeMs;
        const dragDistance = distance([framePosition.x, framePosition.y], [data.x, data.y]);

        if (dragDurationMs > dragTimeThresholdMinMs || dragDistance > dragDistanceThreshold) {
            const newPosition = new Position(data.x, data.y, 'px');
            updateComponentPosition(componentId, newPosition);
            const pointerPosition = getPointerPosition(event);
            tryToSnapComponent(componentId, 'drag', pointerPosition);
        }
        else {
            if (preDragPositionAndSize != null) {
                updateComponentPosition(componentId, preDragPositionAndSize.position);
                updateComponentSize(componentId, preDragPositionAndSize.size);
            }

            onClick();
        }

        setIsMoving(false);
        setPreDragPositionAndSize(null);
    };

    const onResizeStart = () => {
        setIsMoving(true);
        hideComponentSettings();
        showFullStreamMasks();
    };

    const onResize = (_e: any, _dir: any, ref: any, _delta: any, position: any) => {
        const newSize = new Size(parseFloat(ref.style.width), parseFloat(ref.style.height), '%');
        updateComponentSize(componentId, newSize);
    };

    const onResizeStop = (_e: any, _dir: any, ref: any, _delta: any, position: any) => {
        restoreDefaultStreamMasks();

        const newPosition = new Position(position.x, position.y, 'px');
        const newSize = new Size(parseFloat(ref.style.width), parseFloat(ref.style.height), '%');

        updateComponentPosition(componentId, newPosition);
        updateComponentSize(componentId, newSize);
        tryToSnapComponent(componentId, 'resize');
        setIsMoving(false);
    };

    const onClick = () => {
        setComponentFrameActive(componentId, true);
    };

    const onMouseEnter = () => {
        if (!isMoving) {
            if (unmuteStreamsOnHover) {
                unmuteOnlyThisStream(streamId);
            }
    
            showComponentSettings();
        }
    };

    const onMouseLeave = () => {
        if (!isMoving) {
            if (component.state.isFrameActive) {
                setComponentFrameActive(componentId, false);
            }
    
            hideComponentSettings();
        }
    };

    const onMouseMove = () => {
        if (!isMoving) {
            if (unmuteStreamsOnHover && !stream?.state?.isUnmuted) {
                unmuteOnlyThisStream(streamId);
            }
    
            showComponentSettings();
        }
    };

    const showComponentSettings = () => {
        if (!component.state.showSettings) {
            setComponentShowSettings(componentId, true);
        }

        if (settingsTimeout != null) {
            clearTimeout(settingsTimeout);
        }

        if (!embededStreams) {
            const timer = setTimeout(() => {
                setComponentShowSettings(componentId, false);
            }, 3000);

            setSettingsTimeout(timer);
        }
    };

    const hideComponentSettings = () => {
        setComponentShowSettings(componentId, false);

        if (settingsTimeout != null) {
            clearTimeout(settingsTimeout);
            setSettingsTimeout(null);
        }
    };

    const getPointerPosition = (event: { clientX: number, clientY: number } | { touches: React.TouchList | TouchList }): Position => {
        if ('touches' in event) {
            if (event.touches.length > 0) {
                const touch = event.touches[0];
                return new Position(touch.clientX, touch.clientY, 'px');
            }
            else return null;
        }
        else {
            return new Position(event.clientX, event.clientY, 'px');
        }
    };

    const isInteractive = props.isInteractive === true && !embededStreams;
    const enableResizing = {
        top: isInteractive && constantFrameSize?.height == null,
        right: isInteractive && constantFrameSize?.width == null,
        bottom: isInteractive && constantFrameSize?.height == null,
        left: isInteractive && constantFrameSize?.width == null,
        topRight: isInteractive && constantFrameSize?.width == null && constantFrameSize?.height == null,
        bottomRight: isInteractive && constantFrameSize?.width == null && constantFrameSize?.height == null,
        bottomLeft: isInteractive && constantFrameSize?.width == null && constantFrameSize?.height == null,
        topLeft: isInteractive && constantFrameSize?.width == null && constantFrameSize?.height == null
    };

    const minSize = {
        width: constantFrameSize?.width || stream.state.minimumFrameSize?.width || defaultMinSize.width,
        height: constantFrameSize?.height || stream.state.minimumFrameSize?.height || defaultMinSize.height
    };

    const activeFrameClass = component.state.isFrameActive ? classes.activeFrame : '';

    const layerStyle = {
        zIndex: component.state.layer || 1
    };

    return (
        <ResizeAndDragFrame className={`${classes.frame} ${activeFrameClass}`} style={layerStyle}
            onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onMouseMove={onMouseMove}
            resizeHandleStyles={resizeHandleStyles}
            disableDragging={!isInteractive}
            enableResizing={enableResizing}
            minWidth={minSize.width}
            minHeight={minSize.height}
            position={framePosition}
            size={frameSize}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragStop={onDragStop}
            onResizeStart={onResizeStart}
            onResize={onResize}
            onResizeStop={onResizeStop} >
            {props.children}
        </ResizeAndDragFrame>
    );
}

export default connector(withStyles(styles)(StreamFrame));