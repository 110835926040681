import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme: Theme) => createStyles({
    buttonProgress: {
         position: 'absolute',
         top: '50%',
         left: '50%',
         marginTop: -12,
         marginLeft: -12
      }
});

type ButtonProgressProps = WithStyles<typeof styles> & {
    visible?: boolean;
    color?: 'primary'|'secondary';
};

const ButtonProgress: FunctionComponent<ButtonProgressProps> = (props) => {
    const { classes } = props;
    
    const visible = props.visible !== false;
    const color = props.color || 'primary';

    if(!visible) {
        return null;
    }

    return (
        <CircularProgress color={color} size={24} className={classes.buttonProgress} />
    );
}

export default withStyles(styles)(ButtonProgress);