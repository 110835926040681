import { Auth0ContextInterface } from '@auth0/auth0-react';
import { AxiosRequestConfig } from 'axios';

var triedToRequestTokenAlready = false;

export const getRequestAuthConfig = async (authContext: Auth0ContextInterface): Promise<AxiosRequestConfig> => {
    if(triedToRequestTokenAlready && (!authContext || !authContext.isAuthenticated)) {
        return undefined;
    }

    try {
        const token = await authContext.getAccessTokenSilently();
    
        return {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
    }
    catch {
        return undefined;
    }
    finally {
        triedToRequestTokenAlready = true;
    }
    
};