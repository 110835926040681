import { AppDispatch } from '../../site/types/actionTypes';
import { action } from '../../utils/functions/reduxUtils';
import { AppState } from '../../site/types/appState';
import { getMultistream, getStream, getSyncStreams, getVisibleStreams } from '../selectors/playerSelectors';
import { StreamState, PlayerEvent } from '../types/streamState';
import { StreamPayload } from '../types/payloadTypes';
import { types } from './streamActions';
import { updateSynchronizationBaseTime } from '../actions/multistreamActions';
import { detectedMultistreamChanges } from './playerActions';
import { Stream } from '../types/multistream';

export const setStreamSynchronizationSupport = (streamId: number, synchronizationSupport: boolean) =>
    action<StreamPayload<StreamState>>(types.UPDATE_STREAM_STATE, { streamId, data: { synchronizationSupport } });

export const setStreamSynchronizationShelveValue = (streamId: number, synchronizationShelve: number) =>
    action<StreamPayload<StreamState>>(types.UPDATE_STREAM_STATE, { streamId, data: { synchronizationShelve } });

export const toggleStreamSynchronization = (streamId: number, enabledSynchronization: boolean, currentTimeMs: number) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        dispatch(regenerateSynchronizationShelveValueForStreams(currentTimeMs));
        dispatch(action<StreamPayload<boolean>>(types.TOGGLE_STREAM_SYNCHRONIZATION, { streamId, data: enabledSynchronization }));
        dispatch(detectedMultistreamChanges());

        if (enabledSynchronization) {
            dispatch(synchronizeThisStream(streamId, currentTimeMs));
        }
        else if (!getSyncStreams(getState())?.length) {
            dispatch(updateSynchronizationBaseTime(null, null));
        }
    }
};

export const setStreamSynchronization = (streamId: number, synchronization: number, currentTimeMs: number) => {
    return (dispatch: AppDispatch) => {
        dispatch(action<StreamPayload<number>>(types.SET_STREAM_SYNCHRONIZATION, { streamId, data: synchronization }));
        dispatch(detectedMultistreamChanges());
        dispatch(synchronizeThisStream(streamId, currentTimeMs));
    }
};

export const triggerStreamPlayerEvent = (streamId: number, playerEvent: PlayerEvent, playerCurrentTimeMs: number, currentTimeMs: number) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        if (playerEvent === 'onSync') {
            dispatch(setStreamIsSynchronizationRequested(streamId, false)); //clear stream synchronization as first update
        }

        playerCurrentTimeMs = Math.round(playerCurrentTimeMs);
        dispatch(addPlayerEvent(streamId, playerEvent));

        const stream = getStream(getState(), streamId);
        if (stream == null) {
            return;
        }

        if (playerEvent === 'onPlay' || playerEvent === 'onPause') {
            dispatch(setStreamIsPlaying(streamId, playerEvent === 'onPlay'));

            if (playerCurrentTimeMs != null) {
                dispatch(setStreamPlayerTime(streamId, playerCurrentTimeMs, playerEvent === 'onPlay' ? currentTimeMs : null));

                if (stream.state.synchronizationSupport) {
                    dispatch(regenerateSynchronizationShelveValueForStreams(currentTimeMs));
                }
            }
        }

        if (!stream.state.synchronizationSupport || stream.synchronization == null || playerCurrentTimeMs == null) {
            return;
        }

        const syncStreams = getSyncStreams(getState());
        if (syncStreams == null || syncStreams.length === 0) {
            return;
        }

        const synchronizationBaseMs = playerCurrentTimeMs - stream.synchronization;
        const playerEvents = stream.state.playerEvents;

        //console.log(`${JSON.stringify(syncStreams.ids.reduce<any>((map, id) => { map[id] = syncStreams.get(id).state.playerEvents; return map; }, {}))}, new: ${streamId} - ${playerEvent} - ${playerCurrentTimeMs}`);

        if ((playerEvent === 'onPlay' && !playerEventsEndWith(playerEvents, ['onSync', 'onPlay'])) 
            || (playerEvent === 'onPause' && !playerEventsEndWith(playerEvents, ['onSync', 'onPause']))) {
            syncStreams.forEach(stream => {
                dispatch(setStreamIsPlaying(stream.id, playerEvent === 'onPlay'));
            });
        }

        if (playerEventsEndWith(playerEvents, ['onStart', 'onPlay'])) { //Video is automatically played at the start
            if (syncStreams.all(x => playerEventsEndWith(x.state.playerEvents, ['onStart', 'onPlay']))) { //Synchronize all streams at beginning
                dispatch(updateSynchronizationBaseTime(synchronizationBaseMs, currentTimeMs));
                dispatch(synchronizeAllStreams(syncStreams.ids));
            }
            else if (syncStreams.all(x => playerEventsEndWith(x.state.playerEvents, ['onPlay']))) { //Stream is added to multistream
                dispatch(synchronizeThisStream(streamId, currentTimeMs));
            }
        }
        else if (playerEventsEndWith(playerEvents, ['onSync', 'onPause', 'onPlay'])) { //Video is programmatically seeked when paused
            return; //no action
        }
        else if (playerEventsEndWith(playerEvents, ['onPause', 'onPlay'])) { //Video is manually played
            dispatch(updateSynchronizationBaseTime(synchronizationBaseMs, currentTimeMs));
            dispatch(synchronizeAllStreams(syncStreams.ids));
        }
        else if (playerEventsEndWith(playerEvents, ['onPause', 'onBuffer', 'onPlay'])) { //Video is manually seeked 
            dispatch(updateSynchronizationBaseTime(synchronizationBaseMs, currentTimeMs));
            dispatch(synchronizeAllStreams(syncStreams.ids));
        }
        else if (playerEventsEndWith(playerEvents, ['onSync', 'onBuffer', 'onPlay'])) { //Video is programmatically seeked
            return; //no action
        }
        else if (playerEventsEndWith(playerEvents, ['onSync', 'onPlay', 'onBuffer', 'onPlay'])) { //Video is programmatically seeked and played
            return; //no action
        }
        else if (playerEventsEndWith(playerEvents, ['onBuffer', 'onPlay'])) { //Video is auto buffering from slow internet
            dispatch(synchronizeThisStream(streamId, currentTimeMs));
        }
    }
};

const synchronizeThisStream = (streamId: number, currentTimeMs: number) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const multistreamState = getMultistream(getState()).state;

        if (multistreamState.latestSynchronizationBaseMs == null) {
            const stream = getStream(getState(), streamId);

            if (stream != null && stream.synchronization != null && stream.state.latestPlayerTimeMs != null) {
                dispatch(action<StreamPayload<number>>(types.SET_STREAM_SYNCHRONIZATION, { streamId, data: 0 }));
                dispatch(updateSynchronizationBaseTime(stream.state.latestPlayerTimeMs, stream.state.playerTimeSnapshotTimeMs));
                dispatch(regenerateSynchronizationShelveValueForStreams(currentTimeMs));
            }
        }

        dispatch(setStreamIsSynchronizationRequested(streamId, true));
    }
};

const synchronizeAllStreams = (streamIds: Array<number>) => {
    return (dispatch: AppDispatch) => {
        streamIds.forEach(streamId => {
            dispatch(setStreamIsSynchronizationRequested(streamId, true));
        });
    }
};

const regenerateSynchronizationShelveValueForStreams = (currentTimeMs: number) => {
    return (dispatch: AppDispatch, getState: () => AppState) => {
        const visibleStreams = getVisibleStreams(getState());
        const supportedStreams = visibleStreams && visibleStreams.filter(stream => stream.state.synchronizationSupport && stream.state.latestPlayerTimeMs != null);

        if (!supportedStreams || supportedStreams.length === 0) {
            return;
        }

        let latestSynchronizationBaseMs: number;
        let synchronizationBaseSnapshotTimeMs: number;
        const multistreamState = getMultistream(getState()).state;

        const calculateCurrentPlayerTime = (latestPlayerTimeMs: number, playerTimeSnapshotTimeMs: number): number => 
            (playerTimeSnapshotTimeMs != null ? latestPlayerTimeMs + (currentTimeMs - playerTimeSnapshotTimeMs) : latestPlayerTimeMs);

        const calculateCurrentPlayerTimeForStream = (stream: Stream): number => 
            calculateCurrentPlayerTime(stream.state.latestPlayerTimeMs, stream.state.playerTimeSnapshotTimeMs);

        if (multistreamState.latestSynchronizationBaseMs == null) {
            const newSynchronizationBaseStream = supportedStreams.ids.reduce<Stream>((currentBestStream, currentStreamId) => {
                const currentStream = supportedStreams.get(currentStreamId);
                return !currentBestStream || calculateCurrentPlayerTimeForStream(currentStream) < calculateCurrentPlayerTimeForStream(currentBestStream)
                    ? currentStream : currentBestStream;
            }, null);

            latestSynchronizationBaseMs = newSynchronizationBaseStream.state.latestPlayerTimeMs;
            synchronizationBaseSnapshotTimeMs = newSynchronizationBaseStream.state.playerTimeSnapshotTimeMs;
        }
        else {
            latestSynchronizationBaseMs = multistreamState.latestSynchronizationBaseMs;
            synchronizationBaseSnapshotTimeMs = multistreamState.synchronizationBaseSnapshotTimeMs;
        }

        supportedStreams.forEach(stream => {
            const newSynchronizationShelve = calculateCurrentPlayerTimeForStream(stream) 
                - calculateCurrentPlayerTime(latestSynchronizationBaseMs, synchronizationBaseSnapshotTimeMs);
            dispatch(setStreamSynchronizationShelveValue(stream.id, newSynchronizationShelve));
        });

        //const debugStreams = getVisibleStreams(getState()).filter(stream => stream.state.synchronizationSupport && stream.state.latestPlayerTimeMs != null);
        //console.log(JSON.stringify(debugStreams.ids.reduce<any>((map, id) => { map[id] = debugStreams.get(id).synchronization != null ? `Sync(${debugStreams.get(id).synchronization/1000})` : `Shelve(${debugStreams.get(id).state.synchronizationShelve/1000})`; return map; }, {})));
    }
};

const setStreamPlayerTime = (streamId: number, latestPlayerTimeMs: number, playerTimeSnapshotTimeMs: number) =>
    action<StreamPayload<StreamState>>(types.UPDATE_STREAM_STATE, { streamId, data: { latestPlayerTimeMs, playerTimeSnapshotTimeMs } });

const addPlayerEvent = (streamId: number, playerEvent: PlayerEvent) =>
    action<StreamPayload<PlayerEvent>>(types.ADD_PLAYER_EVENT, { streamId, data: playerEvent });

const setStreamIsSynchronizationRequested = (streamId: number, isSynchronizationRequested: boolean) =>
    action<StreamPayload<StreamState>>(types.UPDATE_STREAM_STATE, { streamId, data: { isSynchronizationRequested } });

const setStreamIsPlaying = (streamId: number, isPlaying: boolean) =>
    action<StreamPayload<StreamState>>(types.UPDATE_STREAM_STATE, { streamId, data: { isPaused: !isPlaying } });

const playerEventsEndWith = (streamPlayerEvents: Array<PlayerEvent>, lastPlayerEvents: Array<PlayerEvent>): boolean => {
    if (lastPlayerEvents == null || lastPlayerEvents.length === 0) {
        return true;
    }

    if (streamPlayerEvents == null || streamPlayerEvents.length < lastPlayerEvents.length) {
        return false;
    }

    for (let i = 0; i < lastPlayerEvents.length; ++i) {
        if (lastPlayerEvents[i] !== streamPlayerEvents[streamPlayerEvents.length - lastPlayerEvents.length + i]) {
            return false;
        }
    }

    return true;
};