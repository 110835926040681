import reduceReducers from 'reduce-reducers';
import { multistreamReducer } from './multistreamReducer';
import { streamsContainerReducer } from './streamsContainerReducer';
import { streamReducer } from './streamReducer';
import { componentReducer } from './componentReducer';
import { createReducer } from '../../utils/functions/reduxUtils';
import { PlayerState } from '../types/playerState';
import { StreamDetailsModalData } from '../types/streamDetailsModalData';
import { types } from '../actions/playerActions';
import { NormalizedLayoutTemplates } from '../types/layoutTemplate';
import { PlayerFlags } from '../types/playerFlags';

const initialState: PlayerState = {
    normalizedMultistream: null,
    streamDetailsModalData: null,
    normalizedLayoutTemplates: null,
    multiVideoStreams: null,
    playerFlags: {}
};

const playerReducer = createReducer<PlayerState>({
    [types.UPDATE_STREAM_DETAILS_MODAL_DATA]: (draft, payload: StreamDetailsModalData) => {
        draft.streamDetailsModalData = payload;
    },
    [types.UPDATE_LAYOUT_TEMPLATE_LIST]: (draft, payload: NormalizedLayoutTemplates) => {
        draft.normalizedLayoutTemplates = payload;
    },
    [types.UPDATE_PLAYER_FLAGS]: (draft, playerFlags: PlayerFlags) => {
        draft.playerFlags = { ...draft.playerFlags, ...playerFlags };
    },
    [types.CLEAR_PLAYER_STATE]: () => {
        return initialState;
    }
});

export default reduceReducers(initialState, playerReducer, multistreamReducer, streamsContainerReducer, streamReducer, componentReducer);