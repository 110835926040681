import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { createMultistreamUrl } from '../../utils/playerUrlCreators';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import MultistreamCardMedia, { CardStream } from './MultistreamCardMedia';
import MultistreamCardRoot from './MultistreamCardRoot';

const styles = (theme: Theme) => createStyles({
    footer: {
        marginTop: 8
    }
});

type MultistreamCardProps = WithStyles<typeof styles> & {
    keyId: string;
    title: string;
    description?: string;
    creationDate: string;
    streams: Array<CardStream>;
};

const MultistreamCard: FunctionComponent<MultistreamCardProps> = (props) => {
    const { classes } = props;
    const { keyId, title, description, creationDate, streams } = props;

    const url = createMultistreamUrl(keyId, title);
    const friendlyCreationDate = moment.utc(creationDate).fromNow();

    return (
        <Box m={2}>
            <Link component={RouterLink} to={url} color='inherit' underline='none'>
                <MultistreamCardRoot title={title} description={description} streams={streams}>
                    <CardActionArea>
                        <MultistreamCardMedia streams={streams} />
                        <CardContent>
                            <Typography variant="h6" align='center' noWrap>
                                <b>{title && /\S/.test(title) ? title : <React.Fragment>&nbsp;</React.Fragment>}</b>
                            </Typography>
                            <Typography variant="body1" align='center' noWrap>
                                {description && /\S/.test(description) ? description : <React.Fragment>&nbsp;</React.Fragment>}
                            </Typography>
                            <Typography className={classes.footer} variant="body2" align='center' color="textSecondary" noWrap>
                                Created {friendlyCreationDate}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </MultistreamCardRoot>
            </Link>
        </Box>
    );
}

export default withStyles(styles)(MultistreamCard);