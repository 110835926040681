import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from '../../../site/types/appState';
import Box from '@material-ui/core/Box';
import { getLayoutTemplates, getAllComponents, getLayout } from '../../selectors/playerSelectors';
import MultistreamLayoutMockupCard from './MultistreamLayoutMockupCard';
import { changeMultistreamLayout } from '../../actions/streamsContainerActions';
import { setPreviousLayout } from '../../actions/componentActions';
import { DenormalizedLayout } from '../../types/layoutTemplate';

const styles = (theme: Theme) => createStyles({
    
});

const mapStateToProps = (state: AppState) => ({
    currentComponents: getAllComponents(state),
    layoutTemplates: getLayoutTemplates(state),
    previousLayout: getLayout(state).state.previousLayout,
    editedLayout: getLayout(state).state.editedLayout === true
});

const mapDispatchToProps = {
    changeMultistreamLayout,
    setPreviousLayout
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type LayoutSettingsProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & {};

const LayoutSettings: FunctionComponent<LayoutSettingsProps> = (props) => {
    const { currentComponents, layoutTemplates, previousLayout, editedLayout } = props;
    const { changeMultistreamLayout, setPreviousLayout } = props;

    const selectPreviousLayout = () => {
        const currentLayout: DenormalizedLayout = {
            components: currentComponents.map(x => x)
        };

        changeMultistreamLayout(previousLayout);
        setPreviousLayout(currentLayout);
    };

    const selectLayoutTemplate = (layout: DenormalizedLayout) => {
        if(previousLayout == null || editedLayout) {
            const currentLayout: DenormalizedLayout = {
                components: currentComponents.map(x => x)
            };
    
            changeMultistreamLayout(layout);
            setPreviousLayout(currentLayout);
        }
        else {
            changeMultistreamLayout(layout);
        }
    };

    return (
        <Box>
            <Box display='flex' flexDirection='column'>
                <Box display='flex' flexWrap='wrap' justifyContent='center'>
                    <MultistreamLayoutMockupCard title='Current layout' components={currentComponents} />

                    {previousLayout && 
                        <MultistreamLayoutMockupCard title='Previous layout' components={previousLayout.components} onClick={selectPreviousLayout} />}
                </Box>
                <Box display='flex' flexWrap='wrap' justifyContent='center'>
                    {layoutTemplates?.map(x => 
                        <MultistreamLayoutMockupCard key={x.id} title={x.name} components={x.layout.components} onClick={() => selectLayoutTemplate(x.layout)} />
                        )}
                </Box>
            </Box>
        </Box>
    );
}

export default connector(withStyles(styles)(LayoutSettings));