import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ReactPlayerStream from './ReactPlayerStream';
import { setStreamSynchronizationSupport } from '../../../actions/streamSynchronizationActions';

const mapDispatchToProps = {
    setStreamSynchronizationSupport
};

const connector = connect(null, mapDispatchToProps);

type VideoFileStreamProps = ConnectedProps<typeof connector> & {
    streamId: number;
    sourceKey: string;
};

const VideoFileStream: FunctionComponent<VideoFileStreamProps> = (props) => {
    const { streamId, sourceKey } = props;
    const { setStreamSynchronizationSupport } = props;

    React.useEffect(() => {
        if(streamId !== 0) {
            setStreamSynchronizationSupport(streamId, true);
        }
    }, [streamId, setStreamSynchronizationSupport]);

    return (
        <ReactPlayerStream streamId={streamId} url={sourceKey} />
    );
}

export default connector(VideoFileStream);