import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import MultistreamPlayer from '../components/MultistreamPlayer'
import { fetchMultistream } from '../actions/multistreamActions'
import { useAuth0 } from '@auth0/auth0-react';

const mapDispatchToProps = { 
    fetchMultistream
};

const connector = connect(null, mapDispatchToProps);

type PlayerProps = ConnectedProps<typeof connector> &  RouteComponentProps<{
    key: string
}>;

const Player: FunctionComponent<PlayerProps> = (props) => {
    const { fetchMultistream } = props;
    const authContext = useAuth0();
    const key = props.match.params.key;

    React.useEffect(() => {
        const apiUrl = '/api/multistreams/' + key;
        fetchMultistream(authContext, apiUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    return (
        <MultistreamPlayer />
    );
}



export default connector(Player);