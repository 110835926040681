import { createReducer } from '../../utils/functions/reduxUtils';
import { types } from '../actions/multistreamActions';
import { getMultistream, getAllStreams, getAllComponents } from '../selectors/playerSelectors';
import { PlayerState } from '../types/playerState';
import { NormalizedMultistream, Stream, Component, MultistreamFlags } from '../types/multistream';
import NormalizedEntities from '../../utils/functions/NormalizedEntities';
import { MultistreamState } from '../types/multistreamState';


export const multistreamReducer = createReducer<PlayerState>({
    [types.SET_NORMALIZED_MULTISTREAM]: (draft, normalizedMultistream: NormalizedMultistream) => {
        draft.normalizedMultistream = normalizedMultistream
    },
    [types.EDIT_MULTISTREAM_GENERAL_INFO]: (draft, payload) => {
        const multistream = getMultistream(draft);

        if (multistream != null) {
            multistream.title = payload.title;
            multistream.description = payload.description;
            multistream.isPrivate = payload.isPrivate;
        }
    },
    [types.SET_MULTISTREAM_IS_OWNER]: (draft, isMultistreamOwner: boolean) => {
        const multistream = getMultistream(draft);

        if (multistream != null) {
            multistream.isOwner = isMultistreamOwner;
        }
    },
    [types.UPDATE_MULTISTREAM_IDS]: (draft, payload: NormalizedMultistream) => updateMultistreamIds(draft, payload),
    [types.UPDATE_MULTISTREAM_STATE]: (draft, payload: MultistreamState) => {
        const multistream = getMultistream(draft);

        if (multistream != null) {
            multistream.state = { ...multistream.state, ...payload };
        }
    },
    [types.UPDATE_MULTISTREAM_FLAGS]: (draft, multistreamFlags: MultistreamFlags) => {
        const multistream = getMultistream(draft);

        if (multistream != null) {
            multistream.flags = { ...multistream.flags, ...multistreamFlags };
        }
    }
});

const updateMultistreamIds = (draft: PlayerState, payload: NormalizedMultistream) => {
    const allComponents = getAllComponents(draft);
    var payloadComponents = payload?.result?.layout?.components && payload?.entities?.components &&
        new NormalizedEntities<Component>(payload.result.layout.components, payload.entities.components);
    updateMultistreamEntitiesId(allComponents, payloadComponents);

    const allStreams = getAllStreams(draft);
    var payloadStreams = payload?.result?.streams && payload?.entities?.streams &&
        new NormalizedEntities<Stream>(payload.result.streams, payload.entities.streams);
    updateMultistreamEntitiesId(allStreams, payloadStreams);
};

const updateMultistreamEntitiesId = <T extends { id: number, order: number }>(allEntities: NormalizedEntities<T>, payloadEntities: NormalizedEntities<T>) => {
    payloadEntities = payloadEntities?.filter(x => allEntities?.find(y => y.id === x.id) == null);

    if (payloadEntities != null && payloadEntities.length > 0) {
        for (let i = 0; i < allEntities.length; ++i) {
            const entity = allEntities.at(i);

            if (entity.id > 0) continue;

            const oldEntityId = entity.id;
            const newEntityId = payloadEntities.find(x => x.order === entity.order).id

            allEntities.ids[i] = newEntityId;
            entity.id = newEntityId;
            allEntities.obj[newEntityId] = entity;
            delete allEntities.obj[oldEntityId];
        }
    }
};