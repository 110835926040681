import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import FormState from '../../utils/functions/FormState';
import { sendFeedback } from '../actions/siteActions';
import { Container, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import { AppState } from '../types/appState';
import { useAuth0 } from '@auth0/auth0-react';
import ButtonProgress from '../../utils/components/ButtonProgress';
import { getSiteFlags } from '../selectors/siteSelectors';

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        textAlign: 'center'
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    }
});

const mapStateToProps = (state: AppState) => ({
    isSendFeedbackInProgess: getSiteFlags(state)?.isSendFeedbackInProgess === true
 });

const mapDispatchToProps = {
    sendFeedback
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ContactProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & {};

const Contact: FunctionComponent<ContactProps> = (props) => {
    const { classes } = props;
    const { isSendFeedbackInProgess } = props;
    const { sendFeedback } = props;
    const authContext = useAuth0();

    var formState = FormState.create({
        email: { value: authContext.user?.email, maxLength: 255, regexFormat: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i },
        message: { required: true, maxLength: 10000 }
    });

    const sendFeedbackOnClick = (formValues: any) => {
        sendFeedback(authContext, formValues.email, formValues.message);
    }

    // eslint-disable-next-line no-useless-concat
    const constructEmailAddress = () => ('contact' + '@' + 'multistream.co');

    return (
        <Container className={classes.root}>
            <Typography variant='h5'>
                <b>If you have any comments, suggestions or questions</b>
            </Typography>
            <Typography variant='subtitle1'>
                send an e-mail at <Link href={'mailto:' + constructEmailAddress()}>{constructEmailAddress()}</Link> or use the form below
            </Typography>
            <Box my={6} display="flex" justifyContent="center">
                <Box width='75%'>
                    <TextField 
                        id='email'
                        label='Your email (optional)'
                        margin='normal'
                        variant='outlined'
                        fullWidth
                        value={formState.field('email').value}
                        onChange={formState.field('email').onChange}
                        error={formState.field('email').error}
                        helperText={formState.field('email').helperText}
                    />
                    <TextField 
                        id='message'
                        label='Message'
                        margin='normal'
                        required
                        multiline
                        rows={12}
                        variant='outlined'
                        autoFocus
                        fullWidth
                        value={formState.field('message').value}
                        onChange={formState.field('message').onChange}
                        error={formState.field('message').error}
                        helperText={formState.field('message').helperText}
                    />
                    <Box my={1}>
                        <Button variant='contained' color='primary' onClick={formState.validateAndProcessOnClick(sendFeedbackOnClick)} disabled={isSendFeedbackInProgess}>
                            Send
                            <SendIcon className={classes.rightIcon} />
                            <ButtonProgress visible={isSendFeedbackInProgess} />
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default connector(withStyles(styles)(Contact));