import { normalize, denormalize, schema } from 'normalizr';
import Position from '../../../utils/functions/Position';
import Size from '../../../utils/functions/Size';
import { NormalizedMultistream } from '../../types/multistream';
import { NormalizedLayoutTemplates } from '../../types/layoutTemplate';

export const normalizeMultistream = (denormalizedMultistream: any): NormalizedMultistream => {
    const multistreamSchema = getMultistreamSchema();
    var multistream = normalize(denormalizedMultistream, multistreamSchema);

    processMultistream(multistream);
    multistream.result.streams.forEach((streamId: number) => processStream(multistream.entities.streams[streamId]));
    multistream.result.layout.components.forEach((componentId: number) => processComponent(multistream.entities.components[componentId]));

    return multistream as NormalizedMultistream;
};

export const denormalizeMultistream = (normalizedMultistream: NormalizedMultistream): any => {
    const multistreamSchema = getMultistreamSchema();
    var multistream = denormalize(normalizedMultistream.result, multistreamSchema, normalizedMultistream.entities);

    deprocessMultistream(multistream);
    multistream.streams.forEach((stream: any) => deprocessStream(stream));
    multistream.layout.components.forEach((component: any) => deprocessComponent(component));

    return multistream;
};

export const normalizeLayoutTemplates = (denormalizedLayoutTemplates: any): NormalizedLayoutTemplates => {
    const layoutTemplatesSchema = getLayoutTemplatesSchema();
    var layoutTemplates = normalize(denormalizedLayoutTemplates, layoutTemplatesSchema);

    layoutTemplates.result.forEach((layoutTemplateId: number) => {
        layoutTemplates.entities.layoutTemplates[layoutTemplateId].layout.components.forEach((component: any) => processComponent(component));
    });

    return layoutTemplates as NormalizedLayoutTemplates;
};

const getMultistreamSchema = () => {
    const streamSchema = new schema.Entity('streams');
    const componentSchema = new schema.Entity('components');
    const multistreamSchema = { 
        streams: [streamSchema],
        layout: {
            components: [componentSchema]
        }
    };

    return multistreamSchema;
};

const getLayoutTemplatesSchema = () => {
    const layoutTemplateSchema = new schema.Entity('layoutTemplates');
    const layoutTemplatesSchema = [
        layoutTemplateSchema
    ];

    return layoutTemplatesSchema;
};

const processMultistream = (multistream: any) => {
    multistream.result.state = {};
    multistream.result.layout.state = {};
    multistream.result.flags = (multistream.result.flags || []).reduce((acc: any, curr: any) => {
        acc[curr] = true; 
        return acc;
    }, {});
};

const deprocessMultistream = (multistream: any) => {
    multistream.flags = Object.keys(multistream.flags).filter(flag => multistream.flags[flag] === true);
    delete multistream.layout.state;
    delete multistream.state;
};

export const processStream = (stream: any) => {
    stream.state = {};
};

const deprocessStream = (stream: any) => {
    delete stream.state;
};

export const processComponent = (component: any) => {
    component.position = new Position(component.left, component.top, '%');
    component.size = new Size(component.width, component.height, '%');
    component.state = {};

    delete component.left;
    delete component.top;
    delete component.width;
    delete component.height;
};

const deprocessComponent = (component: any) => {
    var position = (component.position as Position);
    var size = (component.size as Size);

    component.left = position.left;
    component.top = position.top;
    component.width = size.width;
    component.height = size.height;

    delete component.position;
    delete component.size;
    delete component.state;
};